import { useRive } from '@rive-app/react-canvas';
import { useEffect } from 'react';

export default function RiveManWithLaptop() {
  const { rive, RiveComponent, canvas } = useRive({
    autoplay: true,
    src: '/login-animation.riv',
    stateMachines: 'State Machine 1',
  });
  useEffect(() => {
    const inputs = rive?.stateMachineInputs('State Machine 1');
    if (!inputs) {
      return;
    }
    const xInput = inputs.find((i) => i.name === 'xAxis')!;
    const yInput = inputs.find((i) => i.name === 'yAxis')!;

    const listener = (e: MouseEvent) => {
      const rect = canvas!.getBoundingClientRect();
      const normalizedX = (e.clientX - 12 - rect.left) / rect.width;
      const normalizedY = (e.clientY - rect.top) / rect.height;

      xInput.value = 12 - normalizedX * 12;
      yInput.value = 8 + normalizedY * 32;
    };

    document.addEventListener('mousemove', listener);
    return () => document.removeEventListener('mousemove', listener);
  }, [rive, canvas]);

  return (
    <RiveComponent
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      }}
    />
  );
}
