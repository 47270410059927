import { useState } from 'react';
import { Radio, RadioGroup } from '@chakra-ui/react';
import { SingleChoiceQuestion } from '@cdg/ai-core/src/models';

import MarkdownCode from '~/shared/components/MarkdownCode';

import ChoicesContainer from './ChoicesContainer';
import Choice from './Choice';
import AnswerContainer from './AnswerContainer';

export function SingleChoice({
  question,
  selectAnswer,
}: {
  question: SingleChoiceQuestion;
  selectAnswer: (index: number) => void;
}) {
  const [selectedChoice, setSelectedChoice] = useState<string>();

  const handleChange = (stringIndex: string) => {
    setSelectedChoice(stringIndex);
    selectAnswer(parseInt(stringIndex));
  };

  return (
    <AnswerContainer title="Please select the best option">
      <ChoicesContainer>
        <RadioGroup
          value={selectedChoice}
          w={'100%'}
          display={'flex'}
          flexDir={'column'}
          height={'100%'}
        >
          {question.options.map((item, index) => {
            const isLastAnswer = index === question.options.length - 1;
            const isSelectedAnswer = index.toString() === selectedChoice;
            return (
              <Choice
                flex={1}
                key={index}
                isLastChoice={isLastAnswer}
                isSelected={isSelectedAnswer}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleChange(index.toString());
                }}
              >
                <Radio
                  h={'fit-content'}
                  size="lg"
                  _checked={{
                    borderWidth: '5px',
                    borderColor: 'primary.500',
                  }}
                  value={index.toString()}
                />
                <MarkdownCode isAnswer>{item}</MarkdownCode>
              </Choice>
            );
          })}
        </RadioGroup>
      </ChoicesContainer>
    </AnswerContainer>
  );
}
