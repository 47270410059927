import { JSXElementConstructor, ReactElement, ReactNode, useState } from 'react';
import {
  MenuButton,
  MenuList,
  MenuItem,
  Menu as ChakraMenu,
  MenuProps as ChakraMenuProps,
  Button,
  Flex,
  MenuListProps,
} from '@chakra-ui/react';

type MenuItem = { item: ReactNode };
interface MenuProps extends MenuListProps {
  items: MenuItem[];
  placement?: ChakraMenuProps['placement'];
  icon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  text?: string;
  buttonIcon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}

export default function Menu({ items, icon, placement, buttonIcon, ...props }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ChakraMenu
      placement={placement}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      {icon ? (
        <MenuButton>{icon}</MenuButton>
      ) : (
        <MenuButton as={Button} rightIcon={buttonIcon}>
          Actions
        </MenuButton>
      )}

      <MenuList display={isOpen ? 'block' : 'none'} {...props}>
        {items.map(({ item }, index) => {
          const isLastItem = index === items.length - 1;
          return (
            <Flex
              py={'16px'}
              borderBottom={'1px solid'}
              borderColor={isLastItem ? 'transparent' : 'secondary.300'}
              key={index}
              margin={'auto'}
            >
              {item}
            </Flex>
          );
        })}
      </MenuList>
    </ChakraMenu>
  );
}
