import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth';
import { initializeApp } from '@firebase/app';

import { toast } from '~/shared/services/standAloneToast';
import { showErrorToast } from '~/shared/utils/showErrorToast';

import { AuthContext } from './UserAuthContext';
import { logInWithSocial, logOut, loginInWithCustomToken } from './functions';

initializeApp({
  apiKey: 'AIzaSyCC6WR4drFQ6VC24CuZdxKBVnBiadiFRQI',
  authDomain: 'codygo-website.firebaseapp.com',
  projectId: 'codygo-website',
  storageBucket: 'codygo-website.appspot.com',
  messagingSenderId: '518648006371',
  appId: '1:518648006371:web:cb85db0b7c43c1891fa7d6',
  measurementId: 'G-NKJVV47XYP',
});

// const userValidator = async (user: User) => {
//   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//   const email = user!.email!;
//   try {
//     await validateInvited(email);
//   } catch (error) {
//     if ((error as FirebaseError).code === 'permission-denied') {
//       return `${email} not invited.\nEnsure you are using your invited email.`;
//     }
//     return (error as Error).message;
//   }
// };

export function useProvideAuth() {
  const [user, setUser] = useState<AuthContext['user']>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      async (currentUser) => {
        if (currentUser) {
          const idToken = await currentUser.getIdTokenResult();
          const user = { ...currentUser, getIdToken: () => currentUser.getIdToken(), idToken };
          let errorMessage = '';
          if (!currentUser.emailVerified || !currentUser.providerData?.length) {
            errorMessage = 'Unexpected user login';
          }
          setReady(true);
          if (errorMessage) {
            console.log(errorMessage);
            showErrorToast({ toast, title: errorMessage });

            signOut(getAuth());
            return;
          }
          setUser(user);
          return;
        }
        setReady(true);
        setUser(undefined);
      },
      (error) => {
        console.log(error);
        setReady(true);
        showErrorToast({ toast, title: error.message });
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    user,
    ready,
    logInWithSocial,
    loginInWithCustomToken,
    logOut,
  };
}
