import { Question } from '@cdg/ai-core';
import { QuestionAnswer } from '@cdg/ai-shared';

import { SingleChoice } from './SingleChoice';
import { MultipleChoice } from './MultipleChoice';
import Code from './Code';

export default function Answer({
  question,
  setAnswer,
}: {
  question: Question;
  setAnswer: (answer: QuestionAnswer['answer']) => void;
}) {
  switch (question.type) {
    case 'single-choice':
      return (
        <SingleChoice
          key={question.question}
          selectAnswer={(answer) => setAnswer(answer)}
          question={question}
        />
      );
    case 'many-choice':
      return (
        <MultipleChoice
          key={question.question}
          selectAnswers={(answer) => setAnswer(answer)}
          question={question}
        />
      );
    case 'write-code':
    case 'fix-code':
      return (
        <Code
          key={question.question}
          onChange={(answer) => setAnswer(answer)}
          question={question}
        />
      );
    default:
      return <></>;
  }
}
