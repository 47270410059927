import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
);
const baseStyle = definePartsStyle({
  list: {
    py: 0,
    px: '12px',
    zIndex: 999,
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    borderRadius: 'lg',
    minW: '0',
  },
});

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
});
