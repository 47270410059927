import { Stack, Flex, Image, Box, BoxProps, Text, StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import ExamPauseButtonImage from '~/shared/assets/screenshot-exam-pause-button.png';
import ExamPausedImage from '~/shared/assets/screenshot-exam-paused.png';
import MultiChoiceImage from '~/shared/assets/screenshot-exam-multi-choice.png';
import SingleChoiceImage from '~/shared/assets/screenshot-exam-single-choice.png';
import CodeQuestionImage from '~/shared/assets/screenshot-exam-code.png';
import SkillsPageImage from '~/shared/assets/screenshot-skills-page.png';
import SaveAndStartExamImage from '~/shared/assets/screenshot-save-start-exam.png';
import ExamTimerImage from '~/shared/assets/screenshot-exam-timer.png';
import WelcomeMan from '~/shared/assets/welcome-man.png';
import { useAuth } from '~/shared/hooks/useAuth';

export default function InstructionsContent({ ...rest }: StackProps) {
  const { user } = useAuth();

  return (
    <Stack spacing="24px" bg="inherit" {...rest}>
      <Flex mb="20px">
        <Stack flex={2} spacing="28px">
          <Text variant="heading">Hello {user?.displayName},</Text>
          <Text variant="subheading">Welcome to Your Exam Journey with Codygo!</Text>
          <Text>
            Congratulations on being invited to showcase your skills and expertise with the Codygo
            community. We&apos;re here to assess your proficiency across various technologies,
            including AWS, Front-end Development, Cross Technology, and many more. To get you
            started and ensure you make the most out of your experience, here&apos;s what you need
            to know:
          </Text>
        </Stack>
        <Image flex={1} src={WelcomeMan} />
      </Flex>

      <SectionContainer title="Filling Details">
        <Bullet text="We want to know about you. Most of the details are optional but it is best if you fill as much as you can as it will help us in preparing the exam and even more so, in evaluating the exam results and you as a candidate." />
      </SectionContainer>

      <SectionContainer title="Selecting Skills">
        <Bullet text="This is a short process where you are asked to fill in some minimal details. Select which skill you want to show off and to be assessed by a mostly technical exam built specifically for you according to the subjects you picked." />
        <Bullet text="We appreciate a range of skills and levels, though it is not obligatory. Many individuals at codygo are versatile, covering various tiers and technologies as polyglots and true full-stack professionals. Feel free to choose what you excel at; we encourage everyone, regardless of the job applied for, to select any skill that showcases their strengths." />
        <Bullet text="When selecting your skills, you can choose up to 5 to 12 skills, focusing on those you're most familiar with to boost your performance. Don't stress about selecting too many technologies. Just pick the 5-12 skills you know like the back of your hand, and we'll evaluate you based on your performance. The number of skills you picked is always visible on the right." />

        <ImageContainer
          imageDetails={[
            {
              src: SkillsPageImage,
              title: 'Selecting Skills',
              subTitle: 'Choose the skills you are most familiar with to boost your performance',
            },
          ]}
        >
          <TipContainer
            text="Number of Skills You Picked"
            borderBottomLeftRadius={0}
            right="-250px"
            bottom="26%"
            w="190px"
          >
            <TipArrow w="70px" right="100%" />
          </TipContainer>

          <TipContainer text="Select Skills" borderBottomRightRadius={0} left="-190px" bottom="34%">
            <TipArrow w="115px" left="100%" rotate={180} />
          </TipContainer>
        </ImageContainer>

        <Bullet text="Once you've finished the process of selecting your skills and you're prepared to continue, you'll find the ‘save and start exam’ button located at the bottom of the screen. To finalize your choices and move forward, simply scroll down until you reach the button." />

        <ImageContainer
          imageDetails={[
            {
              src: SaveAndStartExamImage,
              title: 'Save and Start Exam',
            },
          ]}
        >
          <TipContainer
            text="Scroll Down To Reach Button"
            borderBottomLeftRadius={0}
            left="-190px"
            bottom="40%"
            transform="rotate(90deg)"
          >
            <TipArrow w="full" left={0} bottom="-13px" rotate={180} />
          </TipContainer>
        </ImageContainer>
      </SectionContainer>

      <SectionContainer title="The Exam">
        <SubTitle>Exam Questions:</SubTitle>
        <Bullet text="The exam will be displayed one question at a time, which will consist of multiple-choice questions, multiple-select questions and practical coding tasks using an editor. The multiple-choice and multiple-select questions require you to choose the best possible option(s) from the options displayed. For the coding task, you will be provided with an editor where you will type in the codes to answer the questions." />

        <ImageContainer
          imageDetails={[
            {
              src: SingleChoiceImage,
              title: 'Multiple Choice',
              subTitle: 'Here, you are to select one option.',
            },
            {
              src: MultiChoiceImage,
              title: 'Multiple Select',
              subTitle:
                'Here, you are to select one or more options that best answer the question.',
            },
            {
              src: CodeQuestionImage,
              title: 'Code Editor',
              subTitle: 'Here, you are to use the editor to type the codes to answer the question.',
            },
          ]}
          w="90%"
        />

        <Bullet text="Your exam may consist of 20-50 questions, depending on the skills you picked." />

        <SubTitle>Exam Timer:</SubTitle>
        <Bullet text="Each question comes with its own time limit displayed at the top right of the screen. The amount of time per question varies per question and it ranges between 20secs to 1 minute." />

        <ImageContainer
          imageDetails={[
            {
              src: ExamTimerImage,
              title: 'Exam Timer',
              subTitle:
                'You can always see the amount of time left as the timer becomes red. Please ensure you answer a question and submit before the timer runs out.',
            },
          ]}
          w="60%"
        >
          <TipContainer text="Timer" borderBottomLeftRadius={0} right="-113px" bottom="27%">
            <TipArrow w="85px" right="100%" />
          </TipContainer>
        </ImageContainer>

        <Bullet text="If you run out of time, the question will be marked as unanswered, and you'll automatically move to the next one. You can always see the amount of time left as the timer becomes red. Please ensure you answer a question and submit before the timer runs out." />
        <Bullet text="It is important to note that there is no overall time limit for the exam, although  the total time taken to complete your exam should not be spread for more than 2 days." />

        <SubTitle>Pause Exam:</SubTitle>
        <Bullet text="During the exam, you don't have to rush. Feel free to pause at any time and resume whenever you need to catch your breath. Just keep in mind that paused time counts toward your overall exam duration." />
        <Bullet text="To activate the pause feature, click on the pause button below the question that is been displayed. It is important to note that the pause happens only after the current question is answered." />

        <ImageContainer
          imageDetails={[
            {
              src: ExamPauseButtonImage,
              title: 'Pause Exam',
              subTitle:
                'Click on the pause button to activate the pause feature. The current question has to be answered and submitted before the exam is paused',
            },
          ]}
        >
          <TipContainer text="Pause Here" borderBottomRightRadius={0} left="-168px" bottom="7%">
            <TipArrow w="90px" left="100%" rotate={180} />
          </TipContainer>
        </ImageContainer>

        <Bullet text="When a question is displayed, you will not have the time or ability to review these instructions. If you need to go over the instructions, activate the pause feature and then you can review the instructions. Feel free to go over it as much as you have to." />

        <ImageContainer
          imageDetails={[
            {
              src: ExamPausedImage,
              title: 'Review Instructions / Terms and Conditions',
              subTitle:
                'Click on these buttons to review the exam instructions and the terms and conditions.',
            },
          ]}
        >
          <TipContainer text="Click Here" borderBottomRightRadius={0} left={[-15, -5]} bottom="31%">
            <TipArrow w="68px" left="100%" rotate={180} />
            <TipArrow w="64px" left="100%" bottom="-6px" rotate={190} />
          </TipContainer>
        </ImageContainer>
        <Stack spacing="25px">
          <Text>
            Remember, it&apos;s not just about getting the correct answers; it&apos;s about
            showcasing your understanding and skills. Consider this your chance to shine! Best of
            luck, and welcome to the codygo community. We&apos;re cheering you on every step of the
            way!
          </Text>
          <Text>Take your time to go over the terms and conditions and accept to get started.</Text>
        </Stack>
      </SectionContainer>
    </Stack>
  );
}
function SubTitle({ children }: { children: ReactNode }) {
  return (
    <Text variant="bodyLgBold" color="secondary.900">
      {children}
    </Text>
  );
}

function Bullet({ text }: { text: string }) {
  return (
    <Flex alignItems="start" gap="10px">
      <Box flexShrink={0} mt="6px" bg="primary.500" boxSize="10px" borderRadius="full" />
      <Text>{text}</Text>
    </Flex>
  );
}

function SectionContainer({ title, children }: { title: string; children: ReactNode }) {
  return (
    <Stack spacing="40px">
      <Text variant="subheading">{title}</Text>
      {children}
    </Stack>
  );
}

interface TipContainerProps extends BoxProps {
  text: string;
  children: ReactNode;
}
function TipContainer({ text, children, ...rest }: TipContainerProps) {
  return (
    <Box
      position="absolute"
      bg="white"
      border="1px"
      borderRadius="24px"
      p="10px"
      borderColor="error.300"
      boxShadow="0px 12.8px 25.6px -3.2px #26446914"
      {...rest}
    >
      {/*  add color #E42B2B to theme */}
      <Text lineHeight="20px" variant="bodyLgSemi" color="#E42B2B">
        {text}
      </Text>
      {children}
    </Box>
  );
}

interface TipArrowProps extends BoxProps {
  rotate?: number;
}
function TipArrow({ rotate, ...rest }: TipArrowProps) {
  const arrowColor = 'error.300';
  return (
    <Box
      position="absolute"
      h="1px"
      bg={arrowColor}
      bottom="-1px"
      transform={`rotate(${rotate}deg)`}
      {...rest}
    >
      <Box
        position="absolute"
        bg="transparent"
        border="1px solid transparent"
        boxSize="5px"
        borderLeftColor={arrowColor}
        borderBottomColor={arrowColor}
        left="-1px"
        top="-2px"
        transform="rotate(45deg)"
      />
    </Box>
  );
}
interface ImageContainerProps extends BoxProps {
  imageDetails: { title: string; subTitle?: string; src: string }[];
  children?: ReactNode;
}
function ImageContainer({ imageDetails, children, ...rest }: ImageContainerProps) {
  return (
    <Flex
      gap="12px"
      w="52%"
      m="auto"
      py="25px"
      px="35px"
      bg="primary.50"
      borderRadius="32px"
      {...rest}
    >
      {imageDetails.map(({ title, subTitle, src }) => (
        <Stack flex={1} alignItems="center" spacing="23px" key={src}>
          {/* add variant to theme */}
          <Text variant="bodyLgBold" fontWeight="600">
            {title}
          </Text>
          <Box w="100%" position="relative">
            <Image w="100%" h="auto" src={src} />
            {children}
          </Box>
          <Text variant="bodyXsReg">{subTitle}</Text>
        </Stack>
      ))}
    </Flex>
  );
}
