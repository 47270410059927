import { StateResponse } from '@cdg/ai-shared';
import { useToast } from '@chakra-ui/react';
import { useAsync } from 'react-use';
import { useEffect } from 'react';

import { useXFetch } from '~/shared/hooks/useXFetch';
import { useAuth } from '~/shared/hooks/useAuth';
import { showErrorToast } from '~/shared/utils/showErrorToast';

import StateSwitch from './StateSwitch';

export default function StateMachine() {
  const xfetch = useXFetch();
  const toast = useToast();
  const { logOut, user } = useAuth();
  const { value, error } = useAsync(() => xfetch<StateResponse>('state'));

  useEffect(() => {
    if (error) {
      console.log({ error });
      const isNotRegistered = error.message === 'No such candidate';
      logOut().then(() => {
        showErrorToast({
          toast,
          title: isNotRegistered ? `The user is not invited` : 'Error connection to server',
          description: isNotRegistered ? (
            <div>
              You cannot sign in using <i>{user?.email}</i>.
              <br />
              Please use the email in the invitation.
            </div>
          ) : (
            error.message
          ),
          options: { duration: 10000 },
        });
      });
    }
  }, [error, toast, logOut, user?.email]);

  if (!value) {
    return <div></div>;
  }
  return <StateSwitch initialResponse={value} />;
}
