import { extendTheme } from '@chakra-ui/react';
import { StyleConfig } from '@chakra-ui/theme-tools';

import { Button } from './button.theme';
import { Text } from './text.theme';
import { inputTheme } from './input.theme';
import { selectTheme } from './select.theme';
import { checkboxTheme } from './checkbox.theme';
import { modalTheme } from './modal.theme';
import { menuTheme } from './menu.theme';

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Jost, sans-serif',
      },
    },
  },
  components: <Record<string, StyleConfig>>{
    Button,
    Text,
    Input: inputTheme,
    Select: selectTheme,
    Modal: modalTheme,
    Checkbox: checkboxTheme,
    Menu: menuTheme,
  },
  colors: {
    primary: {
      50: '#ECF2F8',
      100: '#D6E2F0',
      300: '#F3F4F7',
      500: '#3F72AF',
      700: '#264469',
      800: '#7FA1CA',
    },
    secondary: {
      50: '#F9F9FB',
      100: '#F3F4F7',
      200: '#E4E6EC',
      300: '#D8DAE4',
      500: '#BCC0D0',
      700: '#636B8D',
      800: '#41475D',
      900: '#222530',
    },
    error: {
      50: '#FFF8F8',
      100: '#FCE4E4',
      200: '#F8C9C9',
      300: '#EB6161',
      400: '#BB1818',
      500: '#710E0E',
    },
    success: {
      50: '#EFFAF0',
      100: '#DCF5DC',
      300: '#95E097',
      500: '#4ECB53',
      700: '#268229',
    },
    base: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  fontSizes: {
    1: '12px',
    title: '16px',
    chatText: '14px',
    chatSmallText: '12px',
    chatTinyText: '10px',
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 800,
  },
  breakpoints: {
    base: '0px',
    sm: 'unset',
    md: '1250px',
    lg: 'unset',
  },
  space: {
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px',
    17: '68px',
    18: '72px',
    19: '76px',
    20: '80px',
    21: '84px',
    22: '88px',
    23: '92px',
    24: '96px',
    25: '100px',
    26: '104px',
    27: '108px',
    28: '112px',
    29: '116px',
    30: '120px',
    31: '124px',
    32: '128px',
    33: '132px',
    34: '136px',
    35: '140px',
    36: '144px',
    37: '150px',
    38: '154px',
    39: '158px',
    40: '162px',
    41: '166px',
    42: '170px',
    43: '174px',
    44: '178px',
    45: '182px',
  },
  borderRadius: {
    '3xs': '2px',
    '2xs': '4px',
    xs: '6px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '32px',
    '4xl': '40px',
  },
  shadow: {
    md: '0px 1.603px 3.206px 0px rgba(38, 68, 105, 0.15)',
  },
});
// export const markdownStyles = css`
//   &:has(img) {
//     width: 100%;
//   }
//   word-wrap: break-word; /* Older syntax, supported in most browsers */
//   overflow-wrap: break-word; /* Standard syntax */
// `;
