import { SkillTreeDef } from '@cdg/ai-core/src/models';
import { SelectedSkills } from '@cdg/ai-shared';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import Modal from '~/shared/components/Modal';

import { SkillCard } from './SkillsTreeSection/SkillCard';

interface SelectedSkillsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  skillTrees: SkillTreeDef[];
  selectedSkills: SelectedSkills;
}
type SkillsMap = {
  treeNames: Set<string>;
  groupNames: Set<string>;
  skillNames: Set<string>;
};

const skillIdsToSkillsMap = (skillIds: string[]): SkillsMap =>
  skillIds.reduce<SkillsMap>(
    (acc, skillId) => {
      const skillParts = skillId.split('///');
      acc.treeNames.add(skillParts[0]);
      acc.groupNames.add(skillParts[1]);
      acc.skillNames.add(skillParts[2]);
      return acc;
    },
    { treeNames: new Set(), groupNames: new Set(), skillNames: new Set() }
  );

export default function SelectedSkillsModal({
  isOpen,
  onClose,
  onConfirm,
  skillTrees,
  selectedSkills,
}: SelectedSkillsModalProps) {
  const selectedSkillsMap = useMemo(() => skillIdsToSkillsMap(selectedSkills), [selectedSkills]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      h="80vh"
      minW="clamp(647px, 60vw, 947px)"
      headerProps={{
        title: 'Selected Skills',
        subTitle: `You have selected ${selectedSkills.length} skills. Please review your selections and confirm to start the exam.`,
        style: { px: '30px' },
      }}
      footerProps={{
        primaryButton: { content: 'Confirm', onClick: onConfirm },
        secondaryButton: { content: 'Cancel', onClick: onClose },
      }}
    >
      <Stack spacing="35px" flex={1} overflowY="auto" px="30px" pb="20px">
        {skillTrees
          .filter((tree) => selectedSkillsMap.treeNames.has(tree.name))
          .map((tree) => (
            <Stack key={tree.name} justifyContent="center" spacing="25px" mt={'20px'}>
              <Box textAlign="center">
                <Text variant="heading">{tree.name}</Text>
                <Text>{tree.description}</Text>
              </Box>

              {tree.groups
                ?.filter((group) => selectedSkillsMap.groupNames.has(group.name))
                .map((group) => (
                  <Box key={group.name}>
                    <Text variant="titleLg" textAlign="start">
                      {group.name}
                    </Text>

                    <Flex gap="30px" mt="20px" flexWrap="wrap">
                      {group.skills
                        ?.filter((skill) => selectedSkillsMap.skillNames.has(skill.name))
                        .map((skill) => <SkillCard key={skill.name} skill={skill} />)}
                    </Flex>
                  </Box>
                ))}
            </Stack>
          ))}
      </Stack>
    </Modal>
  );
}
