import { Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ChoicesContainerProps extends FlexProps {
  children: ReactNode;
}
function ChoicesContainer({ children, ...rest }: ChoicesContainerProps) {
  return (
    <Flex
      height={'100%'}
      flexDir="column"
      border={'1px solid'}
      borderColor={'secondary.500'}
      borderRadius={'lg'}
      overflow={'hidden'}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default ChoicesContainer;
