import { UseToastOptions, useToast } from '@chakra-ui/react';

interface ShowErrorToast {
  toast: ReturnType<typeof useToast>;
  title?: React.ReactNode;
  description?: React.ReactNode;
  options?: UseToastOptions;
}
export const showErrorToast = ({ toast, title, description, options }: ShowErrorToast) => {
  toast({
    status: 'error',
    title: title || 'An error occured',
    description: description || '',
    isClosable: true,
    duration: 2500,
    ...options,
  });
};
