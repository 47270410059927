import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthProvider } from '~/shared/hooks/useAuth';
import { LoginPage } from '~/auth';
import { RequireAuth } from '~/shared/components/RequireAuth';
import LinkedInCallbackPage from '~/auth/components/CallbackPage';

import StateMachine from './StateMachine';
import { ErrorPage } from './ErrorPage';

const router = createBrowserRouter([
  {
    path: '/auth/login',
    element: <LoginPage />,
  },
  {
    path: '/auth/callback',
    element: <LinkedInCallbackPage />,
  },
  {
    path: '/',
    element: (
      <RequireAuth redirect="/auth/login">
        <StateMachine />
      </RequireAuth>
    ),
  },
  {
    path: '/*',
    element: <ErrorPage />,
  },
]);

export default function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}
