import { Stack, Flex } from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StateRequest, StateResponse } from '@cdg/ai-shared';
import { useState } from 'react';

import Text from '~/shared/components/Text';
import FileUpload from '~/shared/components/FileUpload';
import Button from '~/shared/components/Button';
import InputField from '~/shared/components/InputField';

import AddressInput from './AddressInput';

type FormData = {
  address: string;
  residence: string;
  cvUrl: string;
  github: string;
  linkedin: string;
  portfolio: string;
};

const getNotValidInputMessage = (fieldName: string) => `Please provide a valid ${fieldName}`;
export default function PersonalDetailsPage({
  doUpdate,
  stateResponse,
  setSelectedState,
}: {
  doUpdate: (params: StateRequest) => void;
  stateResponse: StateResponse;
  setSelectedState: (state: StateResponse['state']) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const existingValues = stateResponse.state === 'need-skills' ? stateResponse.info : undefined;
  const {
    setValue,
    register,
    control,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      address: existingValues?.address || '',
      cvUrl: existingValues?.cvUrl || '',
      github: existingValues?.github || '',
      linkedin: existingValues?.linkedin || '',
      portfolio: existingValues?.portfolio || '',
      residence: existingValues?.residence || '',
    },
  });

  const getErrorText = (fieldName: keyof FormData) => {
    return errors[fieldName] ? errors[fieldName]?.message : undefined;
  };

  const handleAutoCompleteChange = (
    address: string,
    addressComponents?: google.maps.GeocoderAddressComponent[]
  ) => {
    setValue('address', address, { shouldDirty: true });

    if (!addressComponents && address) {
      setError('address', { message: 'Please select one of the options' });
      return;
    }

    const isHouseNumberExists = addressComponents?.some(
      (addressPart) => addressPart.types[0] === 'street_number'
    );
    if (!isHouseNumberExists) {
      setError('address', {
        message: 'Please provide an address with house number',
      });
      return;
    }

    clearErrors('address');
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!isDirty) {
      setSelectedState('need-skills');
      return;
    }
    setIsSubmitting(true);
    const addressParts = data.address.split(', ');
    data.residence = addressParts[addressParts.length - 1];
    await doUpdate({
      currState: 'need-info',
      data,
    });
    setIsSubmitting(false);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const isFormValid = Object.keys(errors).length === 0;
        isFormValid && handleSubmit(onSubmit)(e);
      }}
      style={{ width: '100%' }}
    >
      <Stack spacing={'55px'}>
        <Stack>
          <Text variant="heading">Personal Details</Text>
          <Text>Kindly fill all required sections on this form to continue</Text>
        </Stack>

        <Controller
          name="address"
          control={control}
          rules={{ required: true }}
          defaultValue={existingValues?.address}
          render={({ field }) => (
            <AddressInput
              name="address"
              resultsType="address"
              value={field.value}
              isRequired
              handleInputChange={(address, addressComponents) =>
                handleAutoCompleteChange(address, addressComponents)
              }
              errorText={
                errors?.address?.type === 'required'
                  ? getNotValidInputMessage('address')
                  : getErrorText('address')
              }
              placeholder="Type in your address"
              label="Address"
            />
          )}
        />

        <Stack>
          <Text variant="bodyLgSemi">CV Upload</Text>
          <FileUpload
            base64DefaultFile={existingValues?.cvUrl}
            defaultFileName="Uploaded CV"
            viewText="View CV"
            setValue={(url: string) => setValue('cvUrl', url, { shouldDirty: true })}
          />
        </Stack>

        <InputField
          {...register('github', {
            pattern: {
              value: /^https:\/\/github\.com\/[a-zA-Z0-9-]+\/?$/i,
              message: getNotValidInputMessage('Github link'),
            },
          })}
          errorText={getErrorText('github')}
          placeholder="Link to Github page"
          label="Github Page"
          defaultValue={existingValues?.github}
        />

        <InputField
          {...register('linkedin', {
            pattern: {
              value: /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/i,
              message: getNotValidInputMessage('Linkedin link'),
            },
          })}
          errorText={getErrorText('linkedin')}
          placeholder="Link to Linkedin profile"
          label="Linkedin Profile"
          defaultValue={existingValues?.linkedin}
        />

        <InputField
          {...register('portfolio', {
            pattern: {
              value: /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i,
              message: getNotValidInputMessage('link'),
            },
          })}
          errorText={getErrorText('portfolio')}
          placeholder="Link to Personal Portfolio"
          label="Personal Portfolio"
          defaultValue={existingValues?.portfolio}
        />
      </Stack>

      <Flex marginTop={'60px'} justify="center">
        <Button variant="primaryMd" type="submit" isLoading={isSubmitting}>
          {`${existingValues ? 'Edit' : 'Save'} and Continue`}
        </Button>
      </Flex>
    </form>
  );
}
