import { defineStyleConfig } from '@chakra-ui/react';

const buttonSizes = { lg: '236px', md: '184px', sm: '163px', xs: '141px' };
const primaryStyle = {
  bg: 'primary.500',
  borderColor: 'primary.500',
  color: 'primary.300',
  _disabled: {
    bg: 'secondary.200 !important',
    borderColor: 'secondary.200 !important',
    color: 'secondary.500',
  },
  _hover: { bg: 'primary.700', borderColor: 'primary.700' },
  borderRadius: 'lg',
};
const secondaryStyle = {
  bg: 'transperent',
  color: 'primary.500',
  borderColor: 'primary.500',
  _disabled: {
    borderColor: 'secondary.500 !important',
    color: 'secondary.500 !important',
    fill: 'secondary.500 !important',
  },
  _hover: { borderColor: 'primary.700', color: 'primary.700', fill: 'primary.700' },
  _pressed: { borderColor: 'secondary.700', color: 'primary.700', fill: 'primary.700' },
  borderRadius: 'lg',
  fill: 'primary.500',
};
export const Button = defineStyleConfig({
  baseStyle: {
    border: '2px solid',
    fontSize: '16px',
    fontFamily: 'Jost, sans-serif',
    minHeight: '48px',
    fontWeight: '500',
    padding: '12px, 20px, 12px, 20px',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 2,
    },
    md: {
      fontSize: 'md',
      px: 5,
      py: 3,
    },
    lg: {
      fontSize: 'md',
      px: 3,
      py: 6,
    },
  },
  variants: {
    secondary: { ...secondaryStyle },
    secondaryLg: {
      ...secondaryStyle,
      w: buttonSizes.lg,
    },
    secondaryMd: {
      ...secondaryStyle,
      w: buttonSizes.md,
    },
    secondarySm: {
      ...secondaryStyle,
      w: buttonSizes.sm,
    },
    secondaryXs: {
      ...secondaryStyle,
      w: buttonSizes.xs,
    },
    primary: {
      ...primaryStyle,
    },
    primaryLg: {
      ...primaryStyle,
      w: buttonSizes.lg,
    },
    primaryMd: {
      ...primaryStyle,
      w: buttonSizes.md,
    },
    primarySm: {
      ...primaryStyle,
      w: buttonSizes.sm,
    },
    primaryXs: {
      ...primaryStyle,
      w: buttonSizes.xs,
    },
    text: {
      bgColor: 'none',
      margin: '0px',
      border: '0px',
      padding: '0px !important',
      color: 'primary.500',
      _hover: { opacity: 0.8 },
    },
    outline: {
      py: 1,
      border: '2px solid',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: {
        borderColor: 'primary.700',
        bg: 'secondary.50',
      },
      // _focus: {
      //   boxShadow: 'outline',
      //   borderColor: 'primary.300',
      //   bg: 'primary.500',
      // },
      _disabled: {
        borderColor: 'secondary.300',
        bg: 'secondary.50',
      },
      // _pressed: {
      //   borderColor: 'secondary.700',
      //   bg: 'primary.700',
      // },
    },
    solid: {
      bg: 'primary.500',
      color: 'white',
      _hover: {
        bg: 'primary.400',
      },
      _focus: {
        boxShadow: 'outline',
        border: '2px solid primary.400',
      },
      _disabled: {
        bg: 'secondary.200',
        color: 'secondary.500',
      },
      _pressed: {
        bg: 'red.500',
      },
    },
    pill_solid: {
      borderRadius: '9999px',
      bg: 'primary.500',
      color: 'base.white',
      _hover: {
        bg: 'primary.400',
      },
      // _focus: {
      //   boxShadow: 'outline',
      //   border: '2px solid primary.400',
      // },
      _disabled: {
        bg: 'secondary.200',
      },
      _pressed: {
        bg: 'primary.700',
      },
    },
    pill_outline: {
      borderRadius: '9999px',
      border: '2px solid',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: {
        borderColor: 'primary.700',
        bg: 'secondary.50',
      },
      // _focus: {
      //   boxShadow: 'outline',
      //   borderColor: 'Outline/Lighter',
      //   bg: 'secondary.50',
      // },
      _disabled: {
        borderColor: 'secondary.300',
        bg: 'secondary.50',
      },
      _pressed: {
        borderColor: 'secondary.700',
        bg: 'primary.50',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});
