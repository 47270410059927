import { SkillDef } from '@cdg/ai-core';
import { Card, CardHeader, CardBody, Flex, Checkbox } from '@chakra-ui/react';

import Text from '~/shared/components/Text';

interface SkillCardProps {
  skill: SkillDef;
  onSelectedChanged?: (isSelected: boolean) => void;
  isSelected?: boolean;
}

export function SkillCard({ skill, onSelectedChanged, isSelected }: SkillCardProps) {
  const description: string | string[] | undefined =
    skill.description || skill.subjects?.join(', ');

  return (
    <Card
      p={4}
      boxSize="270px"
      border="1px"
      backgroundColor={isSelected ? 'primary.50' : ''}
      borderColor={isSelected ? 'primary.50' : 'secondary.50'}
      cursor={onSelectedChanged ? 'pointer' : 'auto'}
      transition="box-shadow 0.15s ease-in"
      _hover={{
        transition: 'border 0.15s ease-in',
        borderColor: 'primary.500',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelectedChanged && onSelectedChanged(!isSelected);
      }}
    >
      <CardHeader>
        <Flex direction="column">
          <Flex gap={'16px'} alignItems={'start'}>
            {onSelectedChanged && <Checkbox isChecked={isSelected} />}
            <Text noOfLines={2} variant="bodyLgBold">
              {skill.name}
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody pt={'0px'} overflow="hidden">
        <Text noOfLines={6}>{description}</Text>
      </CardBody>
    </Card>
  );
}
