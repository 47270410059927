import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { python } from '@codemirror/lang-python';
import { sql } from '@codemirror/lang-sql';
import { StreamLanguage } from '@codemirror/language';
import { shell } from '@codemirror/legacy-modes/mode/shell';
import { FixCodeQuestion, WritCodeQuestion } from '@cdg/ai-core/src/models';

import AnswerContainer from './AnswerContainer';

const langMap = {
  bash: StreamLanguage.define(shell),
  css: css(),
  python: python(),
  sql: sql(),
  typescript: javascript({ typescript: true }),
  tsx: javascript({ typescript: true, jsx: true }),
  javascript: javascript({ typescript: false, jsx: false }),
};

export default function Code({
  question,
  onChange,
}: {
  question: FixCodeQuestion | WritCodeQuestion;
  onChange: (answer: string) => void;
}) {
  const lang = /```(\w+)/im.exec(question.answer)?.at(1);
  const ext = lang ? langMap[lang as keyof typeof langMap] : undefined;
  const capitilezdLang = lang ? lang.charAt(0).toUpperCase() + lang.slice(1) : '';

  const cleanClipBoard = () => {
    navigator.clipboard.writeText('');
  };

  return (
    <AnswerContainer
      title={`Please use ${capitilezdLang} to answer`}
      wordsToHighlight={[capitilezdLang]}
      onCopy={cleanClipBoard}
      onClick={cleanClipBoard}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <CodeMirror onChange={onChange} height="300px" extensions={ext ? [ext] : []} />
    </AnswerContainer>
  );
}
