import { Question } from '@cdg/ai-core';
import { Box } from '@chakra-ui/react';

import MarkdownCode from '~/shared/components/MarkdownCode';

export default function Question({ question }: { question: Question }) {
  return (
    <Box w={'100%'}>
      <MarkdownCode>{question.question}</MarkdownCode>
    </Box>
  );
}
