import { useEffect } from 'react';
import { Stack, Flex, Box, useToast, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Codygo from '~/shared/assets/logo-name-circle.svg?react';
import GithubIcon from '~/shared/assets/github-icon.svg?react';
import GoogleIcon from '~/shared/assets/google-icon.svg?react';
import LinkedInIcon from '~/shared/assets/linkedin-icon.svg?react';
import Text from '~/shared/components/Text';
import Button from '~/shared/components/Button';
import { useAuth, SocialProviderId } from '~/shared/hooks/useAuth';
import { showErrorToast } from '~/shared/utils/showErrorToast';
import RiveManWithLaptop from '~/shared/components/RiveManWithLaptop';

const loginButtons = [
  { name: 'Google', Icon: GoogleIcon },
  { name: 'Github', Icon: GithubIcon },
  { name: 'Linkedin', Icon: LinkedInIcon },
];

export default function LoginPage() {
  const navigate = useNavigate();
  const auth = useAuth();
  const toast = useToast();

  function socialSignIn(provider: SocialProviderId) {
    auth
      .logInWithSocial(provider)
      .then((res) => {
        if (res) {
          navigate('/');
        }
      })
      .catch((err) => {
        showErrorToast({
          toast,
          description: err,
          options: { duration: 9000 },
        });
      });
  }

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
  }, [auth.user, navigate]);

  return (
    <Box overflowY={'hidden'}>
      <Flex h="100vh">
        <Center flex={1} bg="secondary.50" px={['40px', '100px']}>
          <Flex maxW={'400px'} minW={'300px'} flexDir={'column'} gap={'10px'} mb={'150px'}>
            <Stack>
              <Box mb={'40px'}>
                <Codygo />
              </Box>
              <Stack spacing={4}>
                <Text variant="heading">Candidates Sign In</Text>
                <Text>
                  Sign In using your social accounts with any of these. Make sure to use the email
                  in the invitation.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              {loginButtons.map(({ name, Icon }) => (
                <Button
                  onClick={() => socialSignIn(name.toLowerCase() as SocialProviderId)}
                  key={name}
                  variant="primary"
                  leftIcon={Icon}
                  fill="white"
                >
                  {name}
                </Button>
              ))}
            </Stack>
          </Flex>
        </Center>
        <Box flex={3} bg="primary.500" overflow={'hidden'} position={'relative'} minW={'500px'}>
          <RiveManWithLaptop />
        </Box>
      </Flex>
    </Box>
  );
}
