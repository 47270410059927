import { InstructionsContent } from '~/info';
import Modal from '~/shared/components/Modal';

interface InstructionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function InstructionsModal({ isOpen, onClose }: InstructionsModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      bg="secondary.50"
      height="80vh"
      minW="clamp(860px, 70vw, 1100px)"
    >
      <InstructionsContent overflowY="auto" overflowX="hidden" pt="56px" px="24px" />
    </Modal>
  );
}
