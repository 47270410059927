import { useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

export function RequireAuth({ redirect, children }: { redirect: string; children: JSX.Element }) {
  const { user } = useAuth();

  const location = useLocation();

  if (!user) {
    return <Navigate to={redirect} state={{ from: location }} replace />;
  }

  return children;
}
