// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

import { theme } from './shared/utils/styles/theme';
import { App } from './app';
import './index.css';
import { ToastContainer } from './shared/services/standAloneToast';

createRoot(document.getElementById('root') as HTMLElement).render(
  <ChakraProvider theme={theme}>
    <App />
    <ToastContainer />
  </ChakraProvider>
);
