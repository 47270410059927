import { useState, useEffect, useMemo } from 'react';
import { Box, BoxProps, useToast } from '@chakra-ui/react';
import { PersonalInfo, SelectedSkills, StateRequest, StateResponse } from '@cdg/ai-shared';
import { useAsyncFn } from 'react-use';

import Navbar from '~/shared/components/Navbar';
import { useXFetch } from '~/shared/hooks/useXFetch';
import { ExamPage, ExamCompletedPage } from '~/exam';
import { SkillsPage } from '~/skills';
import LocationNavbar from '~/shared/components/LocationNavbar';
import { InstructionsPage, PersonalDetailsPage } from '~/info';
import { showErrorToast } from '~/shared/utils/showErrorToast';

const pageStyles: BoxProps = { w: ['920px', '1213px'], px: '30px', m: 'auto' };

const examPages = ['exam', 'paused', 'completed'];

export default function StateSwitch({ initialResponse }: { initialResponse: StateResponse }) {
  const [stateResponse, setStateResponse] = useState<StateResponse>(initialResponse);
  const [selectedState, setSelectedState] = useState<StateResponse['state']>(initialResponse.state);
  const [localSelectionData, setLocalSelection] = useState<{
    skills: SelectedSkills;
    info: PersonalInfo;
  }>({
    info: {},
    skills: [],
  });

  const xfetch = useXFetch();
  const toast = useToast();
  const [{ error, value }, doUpdate] = useAsyncFn((nextParams: StateRequest) =>
    xfetch<StateResponse>('state', { method: 'POST', body: JSON.stringify(nextParams) })
  );

  useEffect(() => {
    if (value) {
      setStateResponse(value);
      setSelectedState(value.state);
    }
  }, [initialResponse, value]);

  useEffect(() => {
    if (error) {
      console.log(error);
      showErrorToast({
        toast,
        title: 'Error updating to server',
        description: (
          <div>
            <i>{error.message}</i>.
            <br />
            If the issue persists, try to sign out and sign in again.
          </div>
        ),
      });
    }
  }, [error, toast]);

  const displayComponent = useMemo(() => {
    switch (selectedState) {
      case 'need-terms':
        return (
          <InstructionsPage
            stateResponse={stateResponse}
            doUpdate={doUpdate}
            setSelectedState={setSelectedState}
          />
        );
      case 'need-info':
        return (
          <PersonalDetailsPage
            stateResponse={stateResponse}
            doUpdate={doUpdate}
            setSelectedState={setSelectedState}
          />
        );
      case 'need-skills':
        return (
          <SkillsPage
            stateResponse={stateResponse}
            doUpdate={doUpdate}
            selectedSkills={localSelectionData.skills}
            onSelectedSkillsChanged={(skills) => setLocalSelection((prev) => ({ ...prev, skills }))}
          />
        );
      case 'exam':
      case 'paused':
        return <ExamPage stateResponse={stateResponse} doUpdate={doUpdate} />;
      case 'completed':
        return <ExamCompletedPage />;
      default:
        return <></>;
    }
  }, [doUpdate, localSelectionData.skills, selectedState, stateResponse]);

  const isExamPage = examPages.includes(stateResponse.state);

  return (
    <Box h="100vh" bg="secondary.50" overflow="auto" key={selectedState}>
      <Box position="sticky" top={0} zIndex={2} minW="fit-content" bg="inherit">
        <Navbar isExamPage={isExamPage} stateResponse={stateResponse} {...pageStyles} />

        {!isExamPage && (
          <LocationNavbar
            stateResponse={stateResponse}
            selectedState={selectedState}
            onSectionClick={(state) => setSelectedState(state)}
            {...pageStyles}
            mt="32px"
          />
        )}
      </Box>

      <Box pt={isExamPage ? '32px' : '72px'} pb="20px" {...pageStyles}>
        {displayComponent}
      </Box>
    </Box>
  );
}
