import { useEffect, useState } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { QuestionAnswer, StateRequest, StateResponse } from '@cdg/ai-shared';

import Button from '~/shared/components/Button';
import RightArrowIcon from '~/shared/assets/right-arrow-icon.svg?react';
import PauseIcon from '~/shared/assets/pause-icon.svg?react';
import ConfirmationDialog from '~/shared/components/ConfirmationDialog';

import Question from './Question';
import ProgressBar from './ProgressBar';
import Timer from './Timer';
import Answer from './Answer';
import ExamPauseModal from './ExamPausedModal';

export default function ExamPage({
  doUpdate,
  stateResponse,
}: {
  doUpdate: (params: StateRequest) => void;
  stateResponse: StateResponse;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isPauseNext, setIsPauseNext] = useState<boolean>(false);
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState<boolean>(false);
  const [currentAnswer, setCurrentAnswer] = useState<QuestionAnswer['answer']>();

  const isPausedState = stateResponse.state === 'paused';
  const isExamState = stateResponse.state === 'exam';

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => isExamState && e.preventDefault();
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isExamState]);

  if (!isExamState && !isPausedState) {
    return null;
  }
  const { question, questionIndex, notAnsweredIndices, questionTimeoutSeconds, totalQuestions } =
    stateResponse;

  const isLastQuestion = questionIndex === totalQuestions - 1;

  const getNextQuestion = async (isTimeOut?: boolean) => {
    setIsSubmitting(true);
    await doUpdate({
      currState: 'exam',
      data: { answer: currentAnswer, questionIndex, isPauseNext, isTimeOut },
    });
    setCurrentAnswer(undefined);
    setIsSubmitting(false);
  };

  return (
    <>
      <Stack spacing="32px">
        <Flex gap={5}>
          <ProgressBar
            h="64px"
            currentNumber={questionIndex + 1}
            maxNumber={totalQuestions}
            notAnsweredIndices={notAnsweredIndices}
          />
          <Timer
            key={questionIndex}
            width="160px"
            durationInSeconds={questionTimeoutSeconds}
            stopTimer={isPausedState}
            onTimeOut={() => getNextQuestion(true)}
          />
        </Flex>
        <Flex
          borderColor="secondary.500"
          w={'full'}
          h={'520px'}
          borderWidth="1px"
          borderRadius="lg"
          paddingX={11}
          paddingY={20}
          gap={'50px'}
          onCopy={(e) => e.preventDefault()}
        >
          <Box w={'50%'}>
            <Question question={question} />
          </Box>
          <Box minH={'100%'} w={'1px'} bg={'secondary.500'} />
          <Box w={'50%'}>
            <Answer setAnswer={(answer) => setCurrentAnswer(answer)} question={question} />
          </Box>
        </Flex>
        <Flex justify="space-between">
          <Button
            variant="secondarySm"
            leftIcon={PauseIcon}
            onClick={() => setIsPauseDialogOpen(true)}
            isDisabled={questionIndex === totalQuestions - 1 || isPauseNext}
          >
            Pause
          </Button>
          <Button
            variant="primarySm"
            isLoading={isSubmitting}
            rightIcon={isLastQuestion ? undefined : RightArrowIcon}
            onClick={() => getNextQuestion()}
          >
            {isLastQuestion ? 'Submit Test' : 'Next'}
          </Button>
        </Flex>
        {isPauseDialogOpen && (
          <ConfirmationDialog
            title="Pause Exam"
            description="Submit the current question  to activate the pause feature"
            onClose={() => setIsPauseDialogOpen(false)}
            primaryButtonProps={{
              text: 'Okay',
              onClick: () => {
                setIsPauseNext(true);
                setIsPauseDialogOpen(false);
              },
            }}
            secondaryButtonProps={{ text: 'Cancel', onClick: () => setIsPauseDialogOpen(false) }}
          />
        )}
      </Stack>
      {stateResponse.pausedAtMilliSec && (
        <ExamPauseModal
          isOpen={isPausedState}
          pausedAtMilliSec={stateResponse.pausedAtMilliSec}
          totalAnsweredQuestions={questionIndex + 1}
          totalQuestions={totalQuestions}
          doUpdate={doUpdate}
        />
      )}
    </>
  );
}
