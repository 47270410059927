import { Flex, FormControl, Input, FormControlProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import Text from './Text';

type FieldProps<TName extends string> = Omit<FormControlProps, 'name'> & {
  placeholder?: string;
} & (
    | UseFormRegisterReturn<TName>
    | { onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; value: string }
  );

export type InputProps<TName extends string> = FieldProps<TName> & {
  errorText?: string;
  type?: HTMLInputElement['type'];
  name: string;
  disabled?: boolean;
};

const InternalInputField = <TName extends string>(
  {
    label,
    type,
    name,
    placeholder,
    errorText,
    disabled,
    isRequired,
    variant = 'Outline',

    ...rest
  }: InputProps<TName>,
  ref: React.Ref<HTMLInputElement> | React.RefObject<null>
) => {
  const id = `${name}${Date.now()}`;
  return (
    <FormControl isInvalid={!!errorText} isDisabled={disabled} isRequired={isRequired} id={id}>
      <Flex flexDirection="column" flex={1} gap={'10px'}>
        <Flex gap={2}>
          <Text variant="bodyLgSemi">{label}</Text>
          {isRequired && (
            <Text variant="bodyLgBold" color="error.400">
              *
            </Text>
          )}
        </Flex>
        <Input
          name={name}
          type={type}
          placeholder={placeholder}
          ref={ref}
          isInvalid={errorText ? true : false}
          variant={variant}
          {...rest}
        />
        {errorText && <Text variant="error">{errorText}</Text>}
      </Flex>
    </FormControl>
  );
};

InternalInputField.displayName = 'InputField';

const InputField = forwardRef(InternalInputField) as <TName extends string>(
  props: InputProps<TName> & { ref?: React.Ref<HTMLInputElement> | React.RefObject<null> }
) => ReturnType<typeof InternalInputField>;

export default InputField;
