import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ErrorPage from '~/shared/components/ErrorPage';
import { useAuth } from '~/shared/hooks/useAuth';
import { toast } from '~/shared/services/standAloneToast';
import { showErrorToast } from '~/shared/utils/showErrorToast';

export default function CallbackPage() {
  const location = useLocation();
  const { loginInWithCustomToken } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const code = search.get('code');
    const state = search.get('state');

    (async () => {
      try {
        if (!code) {
          const error = search.get('error');
          const description = search.get('error_description');
          if (!description) {
            console.error(`Unexpected error ${location.search}`);
            throw new Error(`Unexpected error ${decodeURIComponent(location.search)}`);
          }
          throw new Error(description, { cause: error });
        }
        if (!state) {
          throw new Error('State was not returned in callback');
        }
        const { provider, return_to, redirect_uri } = JSON.parse(state);
        const qs = new URLSearchParams({ code, state, provider });
        const res = await fetch(`/api/v1/auth/linkedin/by-code?${qs}`);
        if (res.status !== 200) {
          let message = '';
          if ((res.headers.get('content-type') || '').includes('application/json')) {
            const obj = await res.json();
            message = obj.message;
          } else {
            message = await res.text().catch(() => '');
          }
          throw new Error(message || res.statusText || 'Unexpected error');
        }
        const data = await res.json();

        console.log({ uid: data.uid, accessToken: data.accessToken, return_to, redirect_uri });

        if (!data.accessToken) {
          console.error(data);
          throw new Error('No accessToken returned');
        }
        await loginInWithCustomToken(data.accessToken);
        window.location.href = '/';
      } catch (error) {
        console.log(error);
        const { message } = error as Error;
        showErrorToast({ toast, title: message });
        setErrorMessage(message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!errorMessage) {
    return null;
  }

  return <ErrorPage message={errorMessage} title="Authentication Error" />;
}
