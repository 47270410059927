import { Flex, Checkbox, Center, Text, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { StateRequest, StateResponse } from '@cdg/ai-shared';

import Button from '~/shared/components/Button';
import { TermsModal } from '~/info/';

import InstructionsContent from './InstructionsContent';

interface IntroductionPageProps {
  doUpdate: (params: StateRequest) => void;
  stateResponse: StateResponse;
  setSelectedState: (state: StateResponse['state']) => void;
}

export default function InstructionsPage({
  doUpdate,
  stateResponse,
  setSelectedState,
}: IntroductionPageProps) {
  const isCurrentState = stateResponse.state === 'need-terms';
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(isCurrentState ? false : true);
  const [viewingTerms, setViewingTerms] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleTermsCheck = () => {
    if (!isCurrentState) {
      return;
    }
    setAgreedToTerms((curr) => !curr);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (isCurrentState) {
      await doUpdate({ currState: 'need-terms' });
    } else {
      setSelectedState('need-info');
    }
    setIsSubmitting(false);
  };

  const toggleTermsDialog = () => {
    setViewingTerms((prev) => !prev);
  };

  return (
    <>
      <Box>
        <InstructionsContent />
        <Flex placeItems="center" gap="5px" mt="40px">
          <Flex
            cursor="pointer"
            pointerEvents={isCurrentState ? undefined : 'none'}
            gap="14px"
            onClick={(e) => {
              e.preventDefault();
              handleTermsCheck();
            }}
          >
            <Checkbox isReadOnly={!isCurrentState} isChecked={agreedToTerms} />
            <Text>I have read and accept the</Text>
          </Flex>
          <Button onClick={toggleTermsDialog} color="primary.500" variant="text">
            Terms and conditions
          </Button>
        </Flex>

        <Center mt="30px">
          <Button
            variant="primaryLg"
            onClick={handleSubmit}
            isDisabled={!agreedToTerms}
            isLoading={isSubmitting}
          >
            {isCurrentState ? 'Get Started' : 'Continue'}
          </Button>
        </Center>
      </Box>
      <TermsModal isOpen={viewingTerms} onClose={toggleTermsDialog} />
    </>
  );
}
