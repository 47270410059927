import { usePlacesWidget } from 'react-google-autocomplete';

import 'react';
import InputField, { InputProps } from '~/shared/components/InputField';

interface AddressInputProps extends Omit<InputProps<'address'>, 'onChange'> {
  value?: string;
  handleInputChange: (
    address: string,
    addressComponents?: google.maps.GeocoderAddressComponent[]
  ) => void;
  resultsType: 'address' | 'country';
}
function AddressInput({ handleInputChange, resultsType, value, ...rest }: AddressInputProps) {
  const onChange = (
    address: string,
    addressComponents?: google.maps.GeocoderAddressComponent[]
  ) => {
    handleInputChange(address, addressComponents);
  };

  const { ref } = usePlacesWidget({
    options: {
      fields: ['formatted_address', 'address_components'],
      types: [resultsType],
    },

    onPlaceSelected: (place) => {
      onChange(place.formatted_address || '', place.address_components);
    },
  });

  return (
    <InputField
      {...rest}
      value={value || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      }}
      ref={ref}
    />
  );
}

export default AddressInput;
