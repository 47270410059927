import { Box, Flex, Avatar, Text, Icon, FlexProps, Center } from '@chakra-ui/react';
import { StateResponse } from '@cdg/ai-shared';

import Logo from '~/shared/assets/logo-white.svg?react';
import TitleLogo from '~/shared/assets/title-logo.svg?react';
import CandidatesTitle from '~/shared/assets/candidates-title.svg?react';

import { useAuth } from '../hooks/useAuth';

import Menu from './Menu';
import Button from './Button';

interface NavbarProps extends FlexProps {
  isExamPage?: boolean;
  stateResponse?: StateResponse;
}
export default function Navbar({ isExamPage, stateResponse, ...rest }: NavbarProps) {
  const { logOut, user } = useAuth();

  const menuItems = [
    {
      item: (
        <Flex gap={'12px'}>
          <Avatar boxSize="40px" size={''} fontSize={'14px'} name={user?.displayName ?? ''} />
          <Flex flexDir={'column'}>
            <Text variant="bodySmReg">{user?.displayName}</Text>
            <Text variant="bodyXsReg">{user?.email}</Text>
          </Flex>
        </Flex>
      ),
    },
    {
      item: (
        <Button onClick={logOut} w="full" variant={'primary'}>
          Log Out
        </Button>
      ),
    },
  ];
  const iconFillColor = isExamPage ? 'primary.500' : 'primary.50';

  return (
    <>
      <Flex
        h="100px"
        bgColor={isExamPage ? 'secondary.100' : 'primary.500'}
        boxShadow={isExamPage ? '0px 1.6px 3.2px 0px #26446926' : ''}
        minW="fit-content"
      >
        <Flex justifyContent="space-between" alignItems="center" {...rest}>
          <Flex placeItems="center" gap={4}>
            <Icon as={Logo} w={'42px'} h={'38px'} fill={iconFillColor} />

            <Center flexDir={'column'} gap={'8px'}>
              <Icon as={TitleLogo} w={'124px'} h={'36px'} fill={iconFillColor} />
              <Icon as={CandidatesTitle} w={'122px'} h={'13px'} fill={iconFillColor} />
            </Center>
          </Flex>

          <Flex gap={'35px'} alignItems={'center'}>
            {stateResponse?.state === 'exam' && (
              <Flex gap={'14px'} alignItems={'center'}>
                <Box boxSize="14px" bgColor="success.300" borderRadius="100%" />
                <Text variant={'normal'} color={'primary.500'}>
                  Exam Active
                </Text>
              </Flex>
            )}
            {user && (
              <Menu
                placement="bottom-end"
                icon={<Avatar name={user?.displayName ?? ''} />}
                items={menuItems}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
