import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);
const baseStyle = definePartsStyle({
  field: {
    borderRadius: '8px',
    focusBorderColor: 'secondary.500',
    border: '1px solid',
    borderColor: 'secondary.300',
    background: 'secondary.100',
    padding: '16px',
    fontSize: '16px',
    height: '56px',
    _placeholder: { color: 'secondary.500' },
    _invalid: {
      borderColor: 'error.400',
      backgroundColor: 'error.50',
      _placeholder: { color: 'error.500' },
      color: 'error.500',
    },
    _focus: {
      borderColor: 'primary.500',
    },
    _disabled: {
      borderColor: 'secondary.500',
    },
  },
  addon: {
    border: '1px solid',
    borderColor: 'gray.200',
    background: 'gray.200',
    borderRadius: 'full',
    color: 'gray.500',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
});
