import { createContext } from 'react';

import { logOut, logInWithSocial, CodygoUser, loginInWithCustomToken } from './functions';

export type AuthContext = {
  logInWithSocial: typeof logInWithSocial;
  logOut: typeof logOut;
  loginInWithCustomToken: typeof loginInWithCustomToken;
  user?: CodygoUser;
};

export const UserAuthContext = createContext<AuthContext>({
  logInWithSocial,

  logOut,
  loginInWithCustomToken,
});
