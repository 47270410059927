import {
  Modal as ChakraModal,
  ModalContentProps as ChakraModalContentProps,
  ModalProps as ChakraModalProps,
  ModalOverlay,
  Button,
  ModalFooter,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  ModalHeaderProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ModalProps extends ChakraModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  modalProps?: Partial<ChakraModalProps>;
  headerProps?: { title: ReactNode; subTitle?: ReactNode; style?: ModalHeaderProps };
  withFooter?: boolean;
  footerProps?: {
    primaryButton?: { content: ReactNode; onClick: () => void };
    secondaryButton?: { content: ReactNode; onClick: () => void };
  };
}

function Modal({
  isOpen,
  onClose,
  children,
  modalProps,
  withFooter = true,
  footerProps,
  headerProps,
  ...rest
}: ModalProps) {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...modalProps}>
      <ModalOverlay />
      <ModalContent {...rest}>
        {headerProps && (
          <ModalHeader {...headerProps.style}>
            <Stack spacing="8px">
              <Text variant="heading">{headerProps?.title}</Text>
              <Text>{headerProps?.subTitle}</Text>
            </Stack>
          </ModalHeader>
        )}
        {children}
        {withFooter && (
          <ModalFooter>
            {footerProps?.secondaryButton && (
              <Button variant="secondarySm" onClick={onClose}>
                {footerProps?.secondaryButton?.content}
              </Button>
            )}
            <Button
              variant="primarySm"
              ml="auto"
              onClick={footerProps?.primaryButton?.onClick || onClose}
            >
              {footerProps?.primaryButton?.content || 'Close'}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
