import { useState } from 'react';
import { Checkbox, CheckboxGroup, Flex } from '@chakra-ui/react';
import { ManyChoicesQuestion } from '@cdg/ai-core/src/models';

import MarkdownCode from '~/shared/components/MarkdownCode';

import AnswerContainer from './AnswerContainer';
import ChoicesContainer from './ChoicesContainer';
import Choice from './Choice';

export function MultipleChoice({
  question,
  selectAnswers,
}: {
  question: ManyChoicesQuestion;
  selectAnswers: (answers: number[]) => void;
}) {
  const [selectedChoices, setSelectedChoices] = useState<number[]>([]);

  const handleChange = (index: number) => {
    let currentValues = [...selectedChoices];
    if (currentValues.includes(index)) {
      currentValues = currentValues.filter((item) => item !== index);
    } else {
      currentValues = [...currentValues, index];
    }
    setSelectedChoices(currentValues);
    selectAnswers(currentValues);
  };

  return (
    <AnswerContainer title="Please select the best options">
      <ChoicesContainer>
        <CheckboxGroup>
          <Flex w={'100%'} display={'flex'} flexDir={'column'} height={'100%'}>
            {question.options.map((option: string, index: number) => {
              const isLastAnswer = index === question.options.length - 1;

              return (
                <Choice
                  flex={1}
                  key={option}
                  isSelected={selectedChoices.includes(index)}
                  isLastChoice={isLastAnswer}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleChange(index);
                  }}
                >
                  <Checkbox isChecked={selectedChoices.includes(index)} />
                  <MarkdownCode isAnswer>{option}</MarkdownCode>
                </Choice>
              );
            })}
          </Flex>
        </CheckboxGroup>
      </ChoicesContainer>
    </AnswerContainer>
  );
}
