import { Stack, Box, StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Text from '~/shared/components/Text';
import Modal from '~/shared/components/Modal';

interface ModalParagraphProps extends StackProps {
  title?: string;
  children: ReactNode;
}
function ModalParagraph({ title, children, ...rest }: ModalParagraphProps) {
  return (
    <Stack spacing="8px" {...rest}>
      {title && <Text variant="bodyLgBold">{title}</Text>}
      {children}
    </Stack>
  );
}

export default function TermsModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        height="80vh"
        minW="clamp(550px, 60vw, 1000px)"
        headerProps={{
          title: 'Terms and Conditions',
          subTitle: 'Kindly review the terms and conditions and accept to continue to your exams.',
          style: { px: '40px' },
        }}
      >
        <Box px="40px" overflowY="auto">
          <ModalParagraph title="Acceptance of Terms:" my={'40px'}>
            <Text>
              By using this platform, you acknowledge that you have read, understood, and agreed to
              these terms and conditions, as well as any future modifications.
            </Text>
          </ModalParagraph>

          <Stack spacing={'20px'}>
            <ModalParagraph title="1. Privacy and Confidentiality:">
              <Text>
                Your privacy is important to us. We will collect and use your personal data,
                including information from your social media profile if you choose to log in via
                social media, in accordance with our privacy policy. Rest assured, your exam
                responses and scores are confidential and will not be shared externally without your
                explicit consent.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="2. Cookies and Behavioral Tracking:">
              <Text>
                We use cookies to track your behaviors on our platform for statistical purposes and
                to enhance your user experience. This is in line with standard web practices for
                analytics and process improvement.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="3. Social Media Login:">
              <Text>
                If you log in using a social media account, you permit us to access certain
                information from your social media profile as needed. We promise not to post
                anything to your social media profiles without your permission.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="4. Intellectual Property:">
              <Text>
                The content on this platform, including exam materials, is protected by intellectual
                property rights and is the property of our organization or our licensors. Please
                respect these rights.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="5. No Employment Guarantee:">
              <Text>
                Participation in these exams is for assessment purposes only and does not guarantee
                employment. Employers or partners associated with this platform are under no
                obligation to offer a job based on your performance.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="6. Conduct and Integrity:">
              <Text>
                We expect you to uphold the highest standards of integrity during your exam. Any
                form of dishonesty, cheating, or plagiarism is strictly prohibited and may lead to
                disqualification.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="7. Limitation of Liability:">
              <Text>
                We are not liable for any outcomes or damages resulting from your use of our
                platform. The system bears no liability to candidates for any claims, damages, or
                losses of any kind.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="8. Access Termination:">
              <Text>
                We reserve the right to terminate or restrict your access to the platform if you are
                found to be in violation of these terms and conditions.
              </Text>
            </ModalParagraph>

            <ModalParagraph title="9. Dispute Resolution:">
              <Text>
                Should any dispute arise related to your use of this platform, it will be resolved
                in accordance with Israeli law through an agreed-upon method such as arbitration or
                mediation.
              </Text>
            </ModalParagraph>

            <Text>
              By proceeding to use our platform, you are agreeing to these terms. We appreciate your
              understanding and cooperation.
            </Text>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
