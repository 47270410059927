import { Flex, FlexProps, Text, useTheme } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';

interface AnswerContainerProps extends FlexProps {
  children: ReactNode;
  title: string;
  wordsToHighlight?: string[];
}

function AnswerContainer({ children, title, wordsToHighlight, ...rest }: AnswerContainerProps) {
  const theme = useTheme();

  return (
    <Flex flexDir={'column'} gap={'8px'} w={'100%'} height={'100%'} {...rest}>
      <Text variant={'bodyMdLight'}>
        <Highlighter
          highlightStyle={{ color: theme.colors.primary[500], backgroundColor: 'transparent' }}
          searchWords={wordsToHighlight ? [...wordsToHighlight] : []}
          autoEscape={true}
          textToHighlight={title}
        />
      </Text>
      {children}
    </Flex>
  );
}

export default AnswerContainer;
