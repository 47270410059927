import { StateRequest } from '@cdg/ai-shared';
import { Box, Flex, Icon, Stack, Center, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { format } from 'date-fns';

import PauseIcon from '~/shared/assets/pause-icon.svg?react';
import RiveManWithLaptop from '~/shared/components/RiveManWithLaptop';
import { TermsModal } from '~/info';
import Modal from '~/shared/components/Modal';
import Button from '~/shared/components/Button';

import InstructionsModal from './InstructionsModal';

interface ExamPauseModalProps {
  isOpen: boolean;
  totalQuestions: number;
  totalAnsweredQuestions: number;
  pausedAtMilliSec: number;
  doUpdate: (params: StateRequest) => void;
}
export default function ExamPausedModal({
  isOpen,
  totalQuestions,
  totalAnsweredQuestions,
  pausedAtMilliSec,
  doUpdate,
}: ExamPauseModalProps) {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  const [isInResumeProcess, setIsInResumeProcess] = useState(false);

  const handleResume = async () => {
    setIsInResumeProcess(true);
    await doUpdate({
      currState: 'paused',
    });
    setIsInResumeProcess(false);
  };

  const formattedPauseDate = format(new Date(pausedAtMilliSec), 'h:mm a dd/MM/yyyy');
  return (
    <Modal
      withFooter={false}
      isOpen={isOpen}
      onClose={() => {}}
      bg="transparent"
      height="473px"
      minW="clamp(630px, 60vw, 850px)"
      modalProps={{ isCentered: true }}
    >
      <Flex h="full">
        <Flex flex={1.1} px="22px" py="50px" bg="secondary.50">
          <Stack spacing={0} alignItems="center" m="auto">
            <Center
              flexShrink={0}
              boxSize="50px"
              border="2px"
              borderColor="primary.500"
              borderRadius="full"
            >
              <Icon as={PauseIcon} boxSize="22px" fill="primary.500" />
            </Center>
            <Stack mt="16px" alignItems="center" spacing="8px">
              {/* add variant to theme */}
              <Text variant="headingSmSemi" fontWeight="600">
                Your Exam is Paused
              </Text>

              <Text textAlign="center">
                {`Exam was paused at ${formattedPauseDate}.`}
                <br />
                You have answered <strong>{totalAnsweredQuestions}</strong> out of
                <strong>{` ${totalQuestions}`}</strong> questions.
              </Text>
            </Stack>

            <Button mt="24px" variant="text" onClick={() => setIsInstructionsModalOpen(true)}>
              Review Instructions
            </Button>

            <Button variant="text" onClick={() => setIsTermsModalOpen(true)}>
              Review Terms and Conditions
            </Button>

            <Button
              mt="24px"
              variant="primaryXs"
              onClick={handleResume}
              isLoading={isInResumeProcess}
            >
              Resume Exam
            </Button>
          </Stack>
        </Flex>

        <Box flex={1} bg="primary.500" position="relative">
          <RiveManWithLaptop />
        </Box>
      </Flex>
      <TermsModal isOpen={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)} />
      <InstructionsModal
        isOpen={isInstructionsModalOpen}
        onClose={() => setIsInstructionsModalOpen(false)}
      />
    </Modal>
  );
}
