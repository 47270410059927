import { ReactNode, PropsWithChildren } from 'react';
import {
  Button as ChakraButton,
  Icon,
  ButtonProps as ChakraButtonProps,
  Center,
} from '@chakra-ui/react';
import { PulseLoader } from 'react-spinners';

type IconSvg = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;
interface CustomButtonProps extends Omit<Omit<ChakraButtonProps, 'leftIcon'>, 'rightIcon'> {
  children?: ReactNode;
  leftIcon?: IconSvg;
  rightIcon?: IconSvg;
  isLoading?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export default function Button({
  children,
  leftIcon,
  rightIcon,
  isLoading,
  size = 'md',
  variant = 'solid',
  isDisabled,
  ...props
}: PropsWithChildren<CustomButtonProps>) {
  return (
    <ChakraButton {...props} size={size} variant={variant} isDisabled={isDisabled || isLoading}>
      <Center gap="2">
        {!isLoading ? (
          <>
            {leftIcon && <Icon as={leftIcon} fill="inherit" fontSize="24px" />}
            {children}
            {rightIcon && <Icon as={rightIcon} fill="inherit" fontSize="24px" />}
          </>
        ) : (
          <PulseLoader color="white" speedMultiplier={0.9} />
        )}
      </Center>
    </ChakraButton>
  );
}
