import { useState, useEffect, useRef } from 'react';
import { Box, HStack, Flex, Center, CenterProps, Icon } from '@chakra-ui/react';

import Text from '~/shared/components/Text';
import TimerRunningIcon from '~/shared/assets/timer-running-icon.svg?react';
import TimerWarningIcon from '~/shared/assets/timer-warning-icon.svg?react';
import TimerElapsedIcon from '~/shared/assets/timer-elapsed-icon.svg?react';

const TIMER_SIZE = '25px';
interface TimerProps extends CenterProps {
  durationInSeconds: number;
  onTimeOut: () => void;
  stopTimer?: boolean;
}

export default function Timer({ durationInSeconds, onTimeOut, stopTimer, ...rest }: TimerProps) {
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const isTimeOutCalled = useRef<boolean>(false);
  const timerStartTime = useRef<number>(Date.now());

  const initialDuration = durationInSeconds * 1000;
  const remainingTime = Math.max(initialDuration - elapsedTime, 0);

  useEffect(() => {
    if (stopTimer || isTimeOutCalled.current) {
      return;
    }
    const timerInterval = setInterval(() => {
      setElapsedTime(Date.now() - timerStartTime.current - 1000);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [stopTimer, timerStartTime]);

  useEffect(() => {
    if (remainingTime === 0 && !isTimeOutCalled.current) {
      onTimeOut();
      isTimeOutCalled.current = true;
    }
  }, [remainingTime, onTimeOut]);

  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  return (
    <Center
      w={'100%'}
      px={4}
      py={1}
      borderColor="secondary.500"
      borderWidth="1px"
      borderRadius={'lg'}
      bgColor="secondary.50"
      {...rest}
    >
      <HStack w={'full'}>
        <Box w={'fit-content'} flexShrink={0}>
          {remainingTime <= 15000 ? (
            <Icon as={TimerElapsedIcon} fill="#FB3144" boxSize={TIMER_SIZE} />
          ) : remainingTime <= 60000 ? (
            <Icon as={TimerWarningIcon} fill="#FB727F" boxSize={TIMER_SIZE} />
          ) : (
            <Icon as={TimerRunningIcon} fill="primary.500" boxSize={TIMER_SIZE} />
          )}
        </Box>
        <Flex direction="column" w={'95px'}>
          <Text variant="light" fontWeight={500} color="secondary.700">
            Time remaining
          </Text>
          <Text variant="titleLg" whiteSpace={'nowrap'}>
            00:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
          </Text>
        </Flex>
      </HStack>
    </Center>
  );
}
