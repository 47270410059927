import { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Center, Flex, Spinner, useToast } from '@chakra-ui/react';
import { SelectedSkills, StateRequest, StateResponse } from '@cdg/ai-shared';
import { useAsync } from 'react-use';
import { SkillTreeConfig } from '@cdg/ai-core';

import Button from '~/shared/components/Button';
import Text from '~/shared/components/Text';
import { useXFetch } from '~/shared/hooks/useXFetch';
import { showErrorToast } from '~/shared/utils/showErrorToast';
import ConfirmationDialog from '~/shared/components/ConfirmationDialog';

import SkillsTreeSection from './SkillsTreeSection';
import SelectedSkillsModal from './SelectedSkillsModal';

function findScrollParent(element: HTMLElement | null): HTMLElement | null {
  while (element) {
    const computedStyle = window.getComputedStyle(element);
    const overflowY = computedStyle.overflowY;
    const isScrollable = overflowY === 'auto' || overflowY === 'scroll' || overflowY === 'overlay';

    if (isScrollable && element.scrollHeight > element.clientHeight) {
      return element;
    }

    element = element.parentElement;
  }
  return null;
}
const SelectionFeedback = ({ value }: { max?: number; min?: number; value: number }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollElement = elementRef.current;

    if (!scrollElement) {
      return;
    }

    const scrollParent = findScrollParent(scrollElement) as HTMLElement;
    let lastScrollY = scrollParent.scrollTop;
    let frame: number;

    const updateElementPosition = () => {
      const deltaY = scrollParent.scrollTop - lastScrollY;
      lastScrollY = scrollParent.scrollTop;

      if (deltaY === 0) {
        return;
      }

      scrollElement.style.transform = `translateY(${deltaY}px)`;

      cancelAnimationFrame(frame);

      // Use requestAnimationFrame for smooth animation back to original position
      // frame = requestAnimationFrame(() => {
      //   scrollElement.style.transform = 'translateY(-50%)';
      // });
    };

    scrollParent.addEventListener('scroll', updateElementPosition);

    return () => {
      scrollParent.removeEventListener('scroll', updateElementPosition);
      cancelAnimationFrame(frame);
    };
  }, []);

  return (
    <Flex
      flexDir={'column'}
      justifyContent={'start'}
      padding={'20px'}
      gap={'45px'}
      alignItems={'center'}
      ref={elementRef}
      position={'fixed'}
      boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
      borderRadius={8}
      background={'white'}
      style={{
        right: '20px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '45px',
        height: '200px',
        transition: 'transform 0.5s ease',
        zIndex: 10,
      }}
    >
      <Text variant="heading" color={'primary.500'}>
        {value}
      </Text>
      <Text transform={'rotate(90deg)'} color={'black'} whiteSpace={'nowrap'}>
        Items Selected
      </Text>
    </Flex>
  );
};

interface SkillPageProps {
  stateResponse: StateResponse;
  selectedSkills: SelectedSkills;
  onSelectedSkillsChanged: (newSkills: SelectedSkills) => void;
  doUpdate: (params: StateRequest) => void;
}

const MAX_SKILLS = 15;
const MIN_SKILLS = 5;

export default function SkillsPage({
  stateResponse,
  selectedSkills,
  onSelectedSkillsChanged,
  doUpdate,
}: SkillPageProps) {
  const [isSkillsOutOfRangeDialogOpen, setIsSkillsOutOfRangeDialogOpen] = useState<boolean>(false);
  const [isSelectedSkillsModalOpen, setIsSelectedSkillsModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();
  const xfetch = useXFetch();
  const { error, value: skillTrees } = useAsync(() => {
    if (stateResponse.state !== 'need-skills') {
      throw new Error(`Unexpected state ${stateResponse.state}`);
    }
    return xfetch<SkillTreeConfig[]>(`skills-tree/${stateResponse.skillsVersion}`);
  });

  useEffect(() => {
    if (error) {
      showErrorToast({ toast, title: 'Error getting skill trees', description: error.message });
    }
  }, [error, toast]);

  const sortedSkillTrees = useMemo(() => {
    return skillTrees ? skillTrees.sort((l, r) => (r.order || 0) - (l.order || 0)) : [];
  }, [skillTrees]);

  function onSelectedLeafSkillChange(id: string, selected: boolean) {
    if (selectedSkills.length === MAX_SKILLS && selected === true) {
      showErrorToast({ toast, title: `You can select a maximum of ${MAX_SKILLS} skills` });
      return;
    }
    const skills = new Set([id, ...selectedSkills]);
    if (!selected) {
      skills.delete(id);
    }
    onSelectedSkillsChanged(Array.from(skills));
  }

  function onSaveClicked() {
    if (selectedSkills.length > MAX_SKILLS || selectedSkills.length < MIN_SKILLS) {
      setIsSkillsOutOfRangeDialogOpen(true);
      return;
    }
    setIsSelectedSkillsModalOpen(true);
  }

  async function onConfirm() {
    setIsSubmitting(true);
    setIsSelectedSkillsModalOpen(false);
    await doUpdate({
      currState: 'need-skills',
      data: selectedSkills,
    });
    setIsSubmitting(false);
  }

  if (stateResponse.state != 'need-skills') {
    return null; // TODO: error
  }

  if (!skillTrees) {
    return (
      <Center mt="100px">
        <Spinner
          thickness="10px"
          speed="0.7s"
          emptyColor="secondary.300"
          color="primary.500"
          boxSize="100px"
        />
      </Center>
    );
  }
  return (
    <>
      <Box w={'100%'}>
        <Flex direction="column" gap={20}>
          {sortedSkillTrees.map((tree) => {
            return (
              <SkillsTreeSection
                selectedSkills={selectedSkills}
                key={tree.name}
                onSelectedLeafSkillChange={onSelectedLeafSkillChange}
                skillTree={tree}
              />
            );
          })}
        </Flex>
        <Flex justify="center" marginTop={15}>
          <Button onClick={onSaveClicked} variant="primaryMd" isLoading={isSubmitting}>
            Save and Start Exam
          </Button>
        </Flex>
      </Box>

      <SelectedSkillsModal
        selectedSkills={selectedSkills}
        skillTrees={sortedSkillTrees}
        isOpen={isSelectedSkillsModalOpen}
        onConfirm={onConfirm}
        onClose={() => setIsSelectedSkillsModalOpen(false)}
      />

      {isSkillsOutOfRangeDialogOpen && (
        <ConfirmationDialog
          title="Selected Skills Below Range "
          description={`You have selected ${selectedSkills.length} skills, please select between ${MIN_SKILLS} to ${MAX_SKILLS} skills`}
          onClose={() => setIsSkillsOutOfRangeDialogOpen(false)}
          primaryButtonProps={{
            text: 'Okay',
            onClick: () => setIsSkillsOutOfRangeDialogOpen(false),
          }}
        />
      )}

      <SelectionFeedback value={selectedSkills.length} />
    </>
  );
}
