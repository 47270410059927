import { SkillTreeConfig } from '@cdg/ai-core';
import { Flex } from '@chakra-ui/react';

import Text from '~/shared/components/Text';

import SkillGroupSection from './SkillGroupSection';

export default function SkillTreeSection({
  skillTree,
  onSelectedLeafSkillChange,
  selectedSkills,
}: {
  skillTree: SkillTreeConfig;
  onSelectedLeafSkillChange: (id: string, selected: boolean) => void;
  selectedSkills: string[];
}) {
  return (
    <Flex direction="column" placeItems="center">
      <Text variant="heading">{skillTree.name}</Text>
      <Text>{skillTree.description}</Text>
      <Flex gap={10} direction="column">
        {skillTree.groups?.map((group) => (
          <SkillGroupSection
            skillLocation={{ treeName: skillTree.name, groupName: group.name }}
            key={group.name}
            skillGroup={group}
            onSelectedLeafSkillChange={onSelectedLeafSkillChange}
            selectedSkills={selectedSkills}
          />
        ))}
      </Flex>
    </Flex>
  );
}
