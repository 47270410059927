import {
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  User,
  IdTokenResult,
  signInWithPopup,
  getAuth,
  signOut,
  signInWithCustomToken,
} from '@firebase/auth';

const socialProviders = {
  google: new GoogleAuthProvider(),
  github: new GithubAuthProvider(),
  linkedin: new OAuthProvider('linkedin.com'),
};

export type SocialProviderId = keyof typeof socialProviders;
export type CodygoUser = User & { idToken: IdTokenResult };

export async function loginInWithCustomToken(token: string) {
  return signInWithCustomToken(getAuth(), token);
}

export async function logInWithSocial(provider: SocialProviderId) {
  if (provider === 'linkedin') {
    const { host, protocol, pathname } = window.location;
    const redirect_uri = `${protocol}//${host}/auth/callback`;
    const qs = new URLSearchParams({
      provider,
      redirect_uri,
      state: JSON.stringify({ provider, redirect_uri, return_to: pathname }),
    });
    const { uri } = await fetch(`/api/v1/auth/linkedin/start-uri?${qs}`).then((r) => r.json());
    window.location.href = uri;
    return;
  }

  return signInWithPopup(getAuth(), socialProviders[provider]).catch(async (error) => {
    try {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const qs = new URLSearchParams({
          accessToken: error.customData._tokenResponse.oauthAccessToken,
        });
        const { accessToken } = await fetch(`/api/v1/auth/github/by-token?${qs}`).then((r) =>
          r.json()
        );
        await signInWithCustomToken(getAuth(), accessToken);
        return;
      }
      throw error;
    } catch (e) {
      console.error(e);
      // toast.error((e as Error).message);
    }
  });
}
export function logOut() {
  return signOut(getAuth());
}
