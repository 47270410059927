import { Text } from '@chakra-ui/react';

import CheckIcon from '~/shared/assets/checked-icon.svg?react';

import ExamNotActiveTemplate from './ExamNotActiveTemplate';

function ExamCompletedPage() {
  return (
    <ExamNotActiveTemplate SideIcon={CheckIcon}>
      <Text mb={'16px'} variant={'subheadingSemi'}>
        Your Exam has been Successfully Completed!
      </Text>
      <Text mb={'30px'} variant={'normal'}>
        You will receive an email shortly, which will contain detailed information regarding your
        exam grades.
      </Text>
      <Text variant={'normal'}>
        Thank you for your hard work, and best of luck with the results!
      </Text>
    </ExamNotActiveTemplate>
  );
}

export default ExamCompletedPage;
