import { Flex, Text, Center, FlexProps, Icon, BoxProps, Box } from '@chakra-ui/react';
import { StateResponse } from '@cdg/ai-shared';

import CheckedIcon from '~/shared/assets/check-v-icon.svg?react';

interface LocationNavbarProps extends FlexProps {
  stateResponse: StateResponse;
  selectedState: StateResponse['state'];
  onSectionClick: (state: StateResponse['state']) => void;
}

type NavSection = { text: string; state: StateResponse['state'] };

const sections: NavSection[] = [
  { text: 'Instructions', state: 'need-terms' },
  { text: 'Personal Details', state: 'need-info' },
  { text: 'Candidate Skills', state: 'need-skills' },
  { text: 'Exam', state: 'exam' },
];

function LocationNavbar({
  selectedState,
  stateResponse,
  onSectionClick,
  ...rest
}: LocationNavbarProps) {
  const selectedStateIndex = sections.findIndex((section) => section.state === selectedState);
  const stateResponseIndex = sections.findIndex((section) => section.state === stateResponse.state);

  const handleClick = (section: NavSection, sectionIndex: number) => {
    if (sectionIndex <= stateResponseIndex) {
      onSectionClick(section.state);
    }
  };

  return (
    <Flex overflow="hidden" {...rest}>
      {sections.map((section, index) => {
        const isLastItem = index === sections.length - 1;

        const isCurrentState = index === stateResponseIndex;
        const isBeforeCurrentState = index < stateResponseIndex;
        const isAfterCurrentState = index > stateResponseIndex;
        const isSelectedState = index === selectedStateIndex;

        const bgColor = isSelectedState ? 'primary.50' : 'secondary.50';
        const borderColor = isAfterCurrentState ? 'secondary.500' : 'primary.500';

        return (
          <Center
            key={section.state}
            onClick={() => handleClick(section, index)}
            position="relative"
            zIndex={sections.length - index}
            w="full"
            h="70px"
            gap="16px"
            bg={bgColor}
            border="1px"
            borderRight={isLastItem ? '1px' : '0px'}
            borderColor={borderColor}
            cursor={index <= stateResponseIndex ? 'pointer' : 'not-allowed'}
            _hover={{
              textDecoration: index <= stateResponseIndex ? 'underline' : 'none',
              color: 'primary.500',
            }}
          >
            <Center
              boxSize="32px"
              borderWidth="1px"
              borderRadius="100%"
              borderColor={borderColor}
              bg={isAfterCurrentState ? 'transparent' : 'primary.500'}
              ml={index > 0 && index < sections.length - 1 ? '30px' : '0px'}
            >
              {isBeforeCurrentState ? (
                <Icon as={CheckedIcon} fill="primary.50" />
              ) : (
                <Text variant="bodyLgSemi" color={isCurrentState ? 'primary.50' : 'secondary.500'}>
                  {index + 1}
                </Text>
              )}
            </Center>

            <Text
              variant="bodyLgSemi"
              color={isAfterCurrentState ? 'secondary.500' : 'primary.500'}
            >
              {section.text}
            </Text>

            <Triangle
              triangleColor={bgColor}
              isLastItem={isLastItem}
              zIndex={1}
              borderWidth="24.5px"
              right="-24px"
            />
            <Triangle
              triangleColor={borderColor}
              isLastItem={isLastItem}
              borderWidth="25.5px"
              right="-25px"
            />
          </Center>
        );
      })}
    </Flex>
  );
}
interface TriangleProps extends BoxProps {
  triangleColor: BoxProps['color'];
  isLastItem: boolean;
}

function Triangle({ triangleColor, isLastItem, ...rest }: TriangleProps) {
  return (
    <Box
      display={isLastItem ? 'none' : 'block'}
      position="absolute"
      transform="rotate(45deg)"
      borderColor="transparent"
      borderTopColor={triangleColor}
      borderRightColor={triangleColor}
      {...rest}
    />
  );
}
export default LocationNavbar;
