import { defineStyleConfig } from '@chakra-ui/react';

export const Text = defineStyleConfig({
  baseStyle: {
    color: 'secondary.800',
    fontFamily: 'Jost, sans-serif',
  },
  sizes: {
    sm: {
      fontSize: '14px',
    },
    md: {
      fontSize: '16px',
    },
    lg: {
      fontSize: '20px',
    },
    xl: {
      fontSize: '22px',
      fontWeight: 700,
    },
  },
  variants: {
    light: {
      fontSize: '12px',
      fontWeight: 300,
    },
    autoComplete: {
      fontSize: '14px',
      color: 'black',
      px: '16px',
      py: '4px',
      _hover: { bg: 'primary.500', color: 'white' },
      cursor: 'pointer',
    },
    normal: {
      fontSize: '16px',
      fontWeight: 400,
    },

    bodyXsReg: {
      fontSize: '12px',
      fontWeight: 400,
    },
    bodySmReg: {
      fontSize: '14px',
      fontWeight: 400,
    },
    bodyLgSemi: {
      fontSize: '16px',
      fontWeight: 500,
    },
    bodyLgBold: {
      fontSize: '16px',
      fontWeight: 700,
    },
    bodyXLLight: {
      fontSize: '20px',
      fontWeight: 300,
      color: 'secondary.900',
    },
    titleLg: {
      fontSize: '22px',
      fontWeight: 500,
    },
    subheadingSemi: {
      fontSize: '22px',
      fontWeight: 500,
      color: 'secondary.900',
    },
    subheading: {
      fontSize: '22px',
      fontWeight: 700,
      color: 'secondary.900',
    },
    headingSmSemi: {
      fontSize: '24px',
      fontWeight: 500,
      color: 'secondary.900',
    },
    heading: {
      fontSize: '32px',
      fontWeight: 700,
      color: 'secondary.900',
    },
    error: {
      fontSize: '16px',
      fontWeight: 500,
      color: 'error.500',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
});
