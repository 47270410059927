import { ReactNode } from 'react';

import { useProvideAuth } from './useAuth';
import { UserAuthContext } from './UserAuthContext';

export function AuthProvider({
  children,
  alwaysReady,
}: // userValidator,
// allowUnverifiedEmail,
{
  children: ReactNode;
  alwaysReady?: boolean;
}) {
  const { ready, ...providerAuth } = useProvideAuth();
  return (
    <UserAuthContext.Provider value={providerAuth}>
      {(ready || alwaysReady) && children}
    </UserAuthContext.Provider>
  );
}
