import { useContext } from 'react';

import { UserAuthContext } from './UserAuthContext';

export type { CodygoUser, SocialProviderId } from './functions';

export { UserAuthContext } from './UserAuthContext';
export { AuthProvider } from './AuthProvider';

export function useAuth() {
  return useContext(UserAuthContext);
}
