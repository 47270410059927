import { useState, useCallback } from 'react';
import { Flex, Link as ChakraLink, FlexProps, Center, Stack } from '@chakra-ui/react';
import { FileRejection, useDropzone } from 'react-dropzone';

import UploadIcon from '../assets/upload-icon.svg?react';
import ViewIcon from '../assets/view-icon.svg?react';
import { toast } from '../services/standAloneToast';
import { showErrorToast } from '../utils/showErrorToast';

import Button from './Button';
import Text from './Text';

const megaByte = 1048576;
const fileMaxSize = megaByte;

function base64ToFile(base64String: string, fileName: string) {
  try {
    const matches = base64String.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
    if (!matches || matches.length !== 3) {
      throw new Error('Invalid base64 string');
    }

    const mimeType = matches[1];
    const base64Data = matches[2];

    const decodedBytes = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(decodedBytes.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < decodedBytes.length; i++) {
      uint8Array[i] = decodedBytes.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
const toBase64 = (
  file: File,
  cb: (result: string | ArrayBuffer | null, isError: boolean, file: File) => void
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result, false, file);
  };
  reader.onerror = function (error) {
    cb(error.toString(), true, file);
  };
};
interface FileUploadProps extends FlexProps {
  setValue: (fileBase64: string) => void;
  viewText: string;
  defaultFileName?: string;
  base64DefaultFile?: string;
  isMultipleFiles?: boolean;
}
export default function FileUpload({
  setValue,
  viewText,
  isMultipleFiles,
  base64DefaultFile,
  defaultFileName,
  ...rest
}: FileUploadProps) {
  const defaultFile = base64DefaultFile
    ? base64ToFile(base64DefaultFile, defaultFileName || 'Uploaded File')
    : undefined;
  const [file, setFile] = useState<File | undefined>(defaultFile);
  const [errorText, setErrorText] = useState<string>('');

  const addFile = useCallback(
    async (result: string | ArrayBuffer | null, isError: boolean, file: File) => {
      if (isError || typeof result !== 'string') {
        showErrorToast({ toast, title: 'Error convert file to base 64' });
        return;
      }
      setValue(result);
      setFile(file);
    },
    [setValue]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const rejectedFile = rejectedFiles[0]?.file;
      if (rejectedFile) {
        const errorText =
          rejectedFile.size > fileMaxSize
            ? 'File size exceeds the maximum allowed (1 MB)'
            : 'File type not supported';
        setErrorText(errorText);
      }
      const newFile = acceptedFiles[0];
      if (!newFile) {
        return;
      }
      setErrorText('');
      toBase64(newFile, addFile);
    },
    [addFile]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc'],
      'text/plain': ['.txt'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxSize: fileMaxSize,
    multiple: !!isMultipleFiles,
  });

  const handleBrowseClick = (e: React.MouseEvent<HTMLElement>) => {
    const { onClick } = getRootProps();
    onClick && onClick(e);
  };
  return (
    <Stack spacing="10px">
      <Center
        borderRadius="lg"
        {...getRootProps()}
        onClick={() => {}}
        border={`1px dashed`}
        borderColor={'secondary.700'}
        bgColor={
          isDragActive ? (isDragReject && !isDragAccept ? 'error.50' : 'success.50') : 'primary.50'
        }
        py={10}
        gap={'12px'}
        position="relative"
        {...rest}
      >
        <Flex pl={2} gap={file ? '0px' : '32px'} alignItems={'center'}>
          <Flex
            alignItems={'center'}
            gap={'12px'}
            borderLeftRadius={file ? 'lg' : 'auto'}
            bgColor={file ? 'white' : ''}
            minH={'48px'}
            px={'5px'}
          >
            <UploadIcon />
            <Text maxW={file ? '40ch' : undefined} isTruncated>
              {file?.name ?? 'Drop file to upload or'}
            </Text>
            <input {...getInputProps()} />
          </Flex>
          <Button
            onClick={handleBrowseClick}
            variant="primary"
            borderLeftRadius={file ? 'sm' : 'auto'}
          >
            Browse File
          </Button>
        </Flex>

        {/* TO DO: add view cv hover effect */}
        {file && (
          <ChakraLink
            href={URL.createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
            textDecoration={'none !important'}
          >
            <Flex
              alignItems={'center'}
              gap={'12px'}
              stroke={'secondary.800'}
              color={'secondary.800'}
              _hover={{ stroke: 'primary.500', color: 'primary.500' }}
            >
              <ViewIcon />
              <Text color={'inherit'}>{viewText}</Text>
            </Flex>
          </ChakraLink>
        )}
      </Center>
      {errorText && <Text variant="error">{errorText}</Text>}
    </Stack>
  );
}
