import { Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ChoiceProps extends FlexProps {
  children: ReactNode;
  isSelected: boolean;
  isLastChoice: boolean;
}
function Choice({ children, isSelected, isLastChoice, ...rest }: ChoiceProps) {
  return (
    <Flex
      alignItems={'center'}
      bgColor={isSelected ? 'primary.50' : ''}
      gap={'16px'}
      p={'16px'}
      borderBottom={isLastChoice ? '' : '1px solid'}
      borderColor={'secondary.500'}
      cursor={'pointer'}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default Choice;
