import { defineStyleConfig } from '@chakra-ui/react';

export const checkboxTheme = defineStyleConfig({
  baseStyle: {
    control: {
      borderRadius: '4px',
      border: '2px solid',
      width: '24px',
      height: '24px',
      iconColor: 'white',
      borderColor: 'primary.500',
      _checked: {
        backgroundColor: 'primary.500',
      },
      _readOnly: { backgroundColor: 'secondary.200', borderColor: 'secondary.500' },
    },
  },
});
