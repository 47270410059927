import { useRef, useEffect } from 'react';
import { BoxProps, Center, Flex } from '@chakra-ui/react';

interface QuestionTabProps extends BoxProps {
  maxNumber: number;
  currentNumber: number;
  notAnsweredIndices: number[];
}

export default function ProgressBar({ maxNumber, currentNumber, ...props }: QuestionTabProps) {
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const numbersArray = Array.from({ length: maxNumber }, (_, index) => index + 1);
  const endTrail = maxNumber - 9;
  const startTrail = currentNumber + 10;

  useEffect(() => {
    if (scrollToRef.current) {
      const container = scrollToRef.current.parentElement;
      if (container) {
        const containerWidth = container.clientWidth;
        const elementWidth = scrollToRef.current.clientWidth;
        const scrollLeft = scrollToRef.current.offsetLeft - (containerWidth - elementWidth) / 2;

        container.scrollLeft = scrollLeft;
      }
    }
  }, [currentNumber]);

  return (
    <Flex
      gap={3}
      alignItems="center"
      bgColor="secondary.50"
      overflowX="auto"
      borderWidth="1px"
      borderColor="secondary.500"
      px={'12px'}
      borderRadius={'lg'}
      w="full"
      {...props}
    >
      {numbersArray.map((questionNumber: number) => (
        <Center
          key={questionNumber}
          ref={questionNumber == currentNumber ? scrollToRef : null}
          bgColor={
            questionNumber < currentNumber
              ? 'primary.100'
              : questionNumber == currentNumber
                ? 'primary.500'
                : ''
          }
          color={
            questionNumber < currentNumber
              ? 'primary.500'
              : questionNumber == currentNumber
                ? 'secondary.100'
                : 'secondary.500'
          }
          boxSize="32px"
          flexShrink={0}
          borderRadius="100%"
          hidden={questionNumber > startTrail && questionNumber < endTrail ? true : false}
        >
          {questionNumber >= startTrail && questionNumber < endTrail ? '...' : questionNumber}
        </Center>
      ))}
    </Flex>
  );
}
