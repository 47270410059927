import { ReactNode } from 'react';
import { Text as ChakraText, TextProps as ChakraTextProps } from '@chakra-ui/react';

interface CustomTextProps extends ChakraTextProps {
  children: ReactNode;
  classNames?: string;
  variant?:
    | 'error'
    | 'light'
    | 'autoComplete'
    | 'normal'
    | 'bodyXsReg'
    | 'bodySmReg'
    | 'bodyLgBold'
    | 'bodyLgSemi'
    | 'titleLg'
    | 'subheading'
    | 'heading';
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export default function Text({ children, variant = 'normal', size, ...props }: CustomTextProps) {
  return (
    <ChakraText size={size} {...props} variant={variant}>
      {children}
    </ChakraText>
  );
}
