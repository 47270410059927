import { defineStyleConfig } from '@chakra-ui/react';

export const modalTheme = defineStyleConfig({
  baseStyle: {
    dialog: {
      borderRadius: '2xl',
      overflow: 'hidden',
    },

    header: {
      py: '25px',
      boxShadow: '0px 1.6px 3.2px 0px #26446926',
      mb: '2px',
    },

    footer: {
      borderTop: '1px',
      borderColor: 'secondary.200',
      position: 'sticky',
      bottom: 0,
      zIndex: 10,
      bg: 'inherit',
      display: 'flex',
    },
  },
});
