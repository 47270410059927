import { useMemo } from 'react';

import { useAuth } from './useAuth';

const baseUrl = '';

export function useXFetch() {
  const { user } = useAuth();
  type FetchParams = Parameters<typeof fetch>;
  const xfetch = useMemo(
    () =>
      async function <T = unknown>(input: FetchParams[0], init?: FetchParams[1]): Promise<T> {
        const token = await user?.getIdToken();
        const xinit: FetchParams[1] = {
          ...init,
          mode: 'cors',
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${token}`,
          },
        };
        const uri =
          typeof input === 'string' && !input.startsWith('http:')
            ? `${baseUrl}/api/v1/${input.replace(/^\//, '')}`
            : input;
        // console.log({ uri, xinit });
        const res = await fetch(uri, xinit);
        let body = undefined;
        try {
          body = await res.json();
        } catch {
          // ignore
        }
        if (res.status !== 200) {
          console.log({ errorBody: body });
          throw new Error(body && 'message' in body ? body.message : res.statusText);
        }
        return body as T;
      },
    [user]
  );
  return xfetch;
}
