import { SkillDef, SkillGroupDef } from '@cdg/ai-core';
import { Box, SimpleGrid } from '@chakra-ui/react';

import Text from '~/shared/components/Text';

import { SkillCard } from './SkillCard';

export default function SkillGroupSection({
  skillGroup,
  onSelectedLeafSkillChange,
  skillLocation,
  selectedSkills,
}: {
  skillLocation: { treeName: string; groupName: string };
  skillGroup: SkillGroupDef;
  onSelectedLeafSkillChange: (id: string, selected: boolean) => void;
  selectedSkills: string[];
}) {
  const generateSkillID = (skill: SkillDef) => {
    return `${skillLocation.treeName}///${skillLocation.groupName}///${skill.name}`;
  };

  return (
    <Box w={'100%'}>
      <Text marginY={10} variant="titleLg">
        {skillGroup.name}
      </Text>
      <SimpleGrid columns={[3, 4]} w={'100%'} gap={6}>
        {skillGroup?.skills?.map((skill) => {
          const skillID = generateSkillID(skill);
          return (
            <SkillCard
              key={skill.name}
              skill={skill}
              isSelected={selectedSkills.includes(skillID)}
              onSelectedChanged={(isSelected) => onSelectedLeafSkillChange(skillID, isSelected)}
            />
          );
        })}
      </SimpleGrid>
    </Box>
  );
}
