import { Flex, Center, Icon, Text, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useAuth } from '~/shared/hooks/useAuth';

interface ExamNotActiveTemplateProps extends FlexProps {
  SideIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  children: ReactNode;
}
function ExamNotActiveTemplate({ SideIcon, children, ...rest }: ExamNotActiveTemplateProps) {
  const { user } = useAuth();
  return (
    <Flex
      border="2px"
      borderColor="secondary.300"
      borderRadius="3xl"
      h="700px"
      overflow="hidden"
      {...rest}
    >
      <Center w="45%" bg="primary.500">
        <Icon as={SideIcon} fill="secondary.50" w="70%" h="full" />
      </Center>
      <Center w={'55%'} textAlign={'center'} px={'24px'} flexDir={'column'}>
        <Text mb={'32px'} variant={'headingSmSemi'}>{`Dear ${user?.displayName},`}</Text>
        {children}
      </Center>
    </Flex>
  );
}

export default ExamNotActiveTemplate;
