import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import remarkEmoji from 'remark-emoji';
import { ComponentProps } from 'react';
import { Box, Text } from '@chakra-ui/react';

import 'highlight.js/styles/github.css';

interface MarkdownCodeProps extends ComponentProps<typeof ReactMarkdown> {
  isAnswer?: boolean;
}
export default function MarkdownCode({ isAnswer, ...props }: MarkdownCodeProps) {
  return (
    <Text variant={isAnswer ? 'normal' : 'bodyXLLight'}>
      <ReactMarkdown
        {...props}
        components={{
          code: ({ children, node }) => {
            if (isAnswer) {
              return <code style={{ whiteSpace: 'break-spaces', border: 'none' }}>{children}</code>;
            }
            const isInline = node && !Object.keys(node.properties).length;

            return isInline ? (
              <code
                style={{
                  fontFamily: 'inherit',
                  backgroundColor: '#E8E8E8',
                  padding: '0px 3px',
                  borderRadius: '6px',
                  whiteSpace: 'nowrap',
                }}
              >
                {children}
              </code>
            ) : (
              <Box
                borderColor="secondary.500"
                borderWidth="1px"
                borderRadius="lg"
                p="10px"
                marginY="12px"
              >
                <Text>
                  <code
                    style={{
                      border: 'none',
                    }}
                  >
                    {children}
                  </code>
                </Text>
              </Box>
            );
          },
        }}
        remarkPlugins={[remarkGfm, remarkEmoji]}
        rehypePlugins={[rehypeHighlight]}
      />
    </Text>
  );
}
