import { useRef } from 'react';
import { AlertDialog, Icon, AlertDialogContent, AlertDialogOverlay, Flex } from '@chakra-ui/react';

import closeIcon from '~/shared/assets/close.svg?react';

import Text from './Text';
import Button from './Button';

type buttonProps = { text: string; onClick: () => void };

interface DialogProps {
  title: string;
  onClose: () => void;
  description?: string;
  primaryButtonProps?: buttonProps;
  secondaryButtonProps?: buttonProps;
}
function ConfirmationDialog({
  title,
  onClose,
  description,
  primaryButtonProps,
  secondaryButtonProps,
}: DialogProps) {
  const cancelRef = useRef(null);

  return (
    <>
      <AlertDialog
        size="lg"
        isOpen={true}
        onClose={() => {}}
        isCentered={true}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent p="24px">
            <Flex
              h={'full'}
              pt={'20px'}
              pb={'32px'}
              w={'100%'}
              position={'relative'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'16px'}
              textAlign="center"
            >
              <Icon
                as={closeIcon}
                cursor={'pointer'}
                position={'absolute'}
                right={'0px'}
                top={'0px'}
                w={'12px'}
                onClick={onClose}
                ref={cancelRef}
              />
              <Text variant="titleLg" color={'secondary.900'}>
                {title}
              </Text>
              <Text variant="normal">{description}</Text>
            </Flex>
            <Flex justify={'center'} align={'center'} gap={'24px'}>
              {secondaryButtonProps && (
                <Button onClick={secondaryButtonProps.onClick} variant="secondarySm">
                  {secondaryButtonProps.text}
                </Button>
              )}
              {primaryButtonProps && (
                <Button onClick={primaryButtonProps.onClick} variant="primarySm">
                  {primaryButtonProps.text}
                </Button>
              )}
            </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default ConfirmationDialog;
